interface IProps {
  isVisiblePassword: Boolean
  setIsVisiblePassword: (arg: boolean) => void
}

const Eye = ({ isVisiblePassword, setIsVisiblePassword }: IProps) => {
  return (
    <span
      className="eye"
      onClick={() => {
        setIsVisiblePassword(!isVisiblePassword)
      }}
    >
      {isVisiblePassword ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.49399 13.9582L2.48474 15.9697C2.19224 16.263 2.19224 16.7377 2.48474 17.0302C2.77799 17.3235 3.25349 17.3227 3.54599 17.0302L5.81624 14.757C7.38974 15.6187 9.24899 16.3275 11.253 16.473L11.265 19.503C11.2665 19.917 11.604 20.2515 12.018 20.25C12.432 20.2485 12.7665 19.911 12.765 19.497L12.753 16.4722C14.4637 16.3477 16.0687 15.8122 17.4795 15.1222C17.481 15.1237 17.4825 15.1252 17.4847 15.1267L20.1345 17.7802C20.4277 18.0727 20.9025 18.0735 21.1957 17.7802C21.4882 17.4877 21.4882 17.013 21.1957 16.7197L18.8512 14.3722C21.4125 12.825 23.0587 11.0002 23.0587 11.0002C23.3347 10.692 23.3085 10.218 23.0002 9.94124C22.692 9.66524 22.218 9.69149 21.9412 9.99974C21.9412 9.99974 17.4937 15 12 15C6.50624 15 2.05874 9.99974 2.05874 9.99974C1.78199 9.69149 1.30799 9.66524 0.999739 9.94124C0.691489 10.218 0.665239 10.692 0.941239 11.0002C0.941239 11.0002 2.31074 12.5182 4.49399 13.9582Z"
            fill="#71706F"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
            fill="#71706F"
          />
        </svg>
      )}
    </span>
  )
}

export default Eye
