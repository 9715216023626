import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IconFilter } from "../utils/Icons/CustomIcons"

const GuestsFilterOpen = ({
  filter,
  setFilter,
  setCheckedFilter,
  checkedFilter,
}: any) => {
  const { t } = useTranslation()

  const onCheck = (name: any) => {
    if (checkedFilter === name) {
      setCheckedFilter("")
      setFilter({ ...filter, statusNumber: "0,1,2,5,6" })
    } else {
      setCheckedFilter(name)
      let status: any
      switch (name) {
        case "scanned":
          status = "4"
          break
        case "failed":
          status = "6"
          break
        case "going":
          status = "1"
          break
      }
      setFilter({ ...filter, statusNumber: status })
    }
  }

  return (
    <Dropdown
      className={
        "dropdown-block dropdown--not-arrow dropdown-filter guests-filter"
      }
    >
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent event-page__btn">
        <IconFilter />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <span className="dropdown-filter__title">
          {t("event.filter.status.title")}
        </span>
        <div className="check-list">
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-scanned"
              checked={checkedFilter === "scanned"}
              onChange={() => onCheck("scanned")}
            />
            <label htmlFor="filter-scanned">
              {t("event.filter.status.scanned")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-failed"
              checked={checkedFilter === "failed"}
              onChange={() => onCheck("failed")}
            />
            <label htmlFor="filter-failed">
              {t("event.filter.status.failed")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-going"
              checked={checkedFilter === "going"}
              onChange={() => onCheck("going")}
            />
            <label htmlFor="filter-going">
              {t("event.filter.status.going")}
            </label>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestsFilterOpen
