import { useTranslation } from "react-i18next"
import ChangePassword from "../../components/Auth/ChangePassword"

const ChangePasswordPage = () => {
  const { t } = useTranslation()

  return (
    <div className="login w-100 p-sm-4 px-2 py-3 m-auto">
      <div className="login-head">
        <h2 className="login__title w-100 text-center">
          {t("changePassword.title")}
        </h2>
        <span className="login-desc w-100 text-center text-body-tertiary d-block mt-2">
          {t("changePassword.description")}
        </span>
      </div>
      <ChangePassword />
    </div>
  )
}

export default ChangePasswordPage
