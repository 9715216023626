import React from "react"
import { Image } from "react-konva"

interface URLImageProps {
  src: any
  x: number
  y: number
  color?: string
  funcClick?: any
  opacity?: any
  width: any
  height: any
}

const URLImage: React.FC<URLImageProps> = ({
  src,
  x,
  y,
  color,
  funcClick,
  opacity,
  width,
  height,
}) => {
  if (funcClick) {
    return (
      <Image
        opacity={opacity}
        width={width}
        height={height}
        x={x}
        y={y}
        image={src}
        fill={color}
        onClick={funcClick}
        onTap={funcClick}
      />
    )
  } else {
    return (
      <Image
        opacity={opacity}
        width={width}
        height={height}
        x={x}
        y={y}
        image={src}
        fill={color}
      />
    )
  }
}

export { URLImage }
