import React from "react"
import { Image } from "react-konva"

interface URLImageProps {
  src: any
  x: number
  y: number
  color?: string
  funcClick?: any
  opacity?: any
  width: any
  height: any
  draggable?: any
  qrBlock?: any
  setQrBlock?: any
}

const QRCodeBtn: React.FC<URLImageProps> = ({
  src,
  x,
  y,
  color,
  opacity,
  width,
  height,
  draggable = false,
  qrBlock,
  setQrBlock,
}) => {
  return (
    <Image
      opacity={opacity}
      width={width}
      height={height}
      x={x}
      y={y}
      image={src}
      fill={color}
      draggable={draggable === true ? true : false}
      onDragMove={(e) => {
        if (
          e.target.attrs.x + 22 - qrBlock.x > 21 &&
          e.target.attrs.y - 5 - qrBlock.y > 21
        ) {
          setQrBlock({
            ...qrBlock,
            width: e.target.attrs.x + 22 - qrBlock.x,
            height: e.target.attrs.y - 5 - qrBlock.y,
          })
        } else {
          e.target.position({
            x: qrBlock.x + qrBlock.width - 22,
            y: qrBlock.y + qrBlock.height + 5,
          })
        }
      }}
    />
  )
}

export { QRCodeBtn }
