import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { IconSearch } from "../../utils/Icons/CustomIcons"
import { IFilter } from "../types"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { setSearchValue } from "@/redux/slices/events/eventsSlice"

const EventsSearch = ({ filter, setFilter }: IFilter) => {
  const { t } = useTranslation()
  const { search_value } = useAppSelector((state) => state.events)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setFilter({ ...filter, searchValue: search_value })
  }, [search_value])

  return (
    <div className="input-search">
      <input
        type="text"
        placeholder={t("placeholder.search")}
        value={search_value}
        onChange={(event) => dispatch(setSearchValue(event.target.value))}
      />

      <button className="input-search__btn">
        <IconSearch />
      </button>
    </div>
  )
}

export default EventsSearch
