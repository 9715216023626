import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { t } from "i18next"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { useParams } from "react-router-dom"
import { sendReminder } from "../../../redux/slices/events/eventsSlice"
import { setBalance } from "../../../redux/slices/auth/authSlice"
import { getContactsOnEvent } from "../../../redux/slices/contacts/contactsSlices"
import styles from "./PreviewModal.module.scss"

const PreviewModal = ({
  show,
  setShow,
  checked,
  setChecked,
  setAllGuestsChecked,
  value,
  filter,
}: any) => {
  const event = useAppSelector((state) => state.events.currentEvent)
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const { lang } = useAppSelector((state) => state.auth)

  const onSendReminder = () => {
    if (checked.length > 0) {
      dispatch(
        sendReminder({
          event_contact_ids: checked.join(),
          event_id: Number(event_id),
          reminder_type: value,
        }),
      ).then((result) => {
        if (result.payload) {
          dispatch(setBalance(result.payload.balance))
        }
      })
      dispatch(getContactsOnEvent(filter))
      setShow(false)
      setChecked([])
      setAllGuestsChecked([])
      notify(t("notify.reminderSent"), false)
    }
  }

  const notify = (text: string, color: boolean) => {
    color
      ? toast.warning(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  return (
    <Modal
      className={`${styles.testModal} modal-reminders__preview`}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{t("preview.title")}</h2>
          <span className={styles.text}>
            {value === 1
              ? t("labels.reminderWithOption")
              : t("labels.reminder")}
          </span>
          <div className={styles.phone}>
            <div className="preview-phone">
              <div className="preview-content">
                <div className="preview-content__wrap">
                  <div className="preview-content__inner">
                    <picture className="picture">
                      <img
                        src={
                          event?.pattern
                            ? event?.pattern
                            : "/vector/no-foto.svg"
                        }
                        alt=""
                      />
                    </picture>
                    {lang === "en" ? (
                      <span>
                        <p>Mr John Doe</p>
                        <p>
                          We are pleased to invite you to {event?.name} event
                          (reminder)
                        </p>
                      </span>
                    ) : (
                      <p> فلان الفلاني نحن سعداء بدعوتك إلى {event?.name}</p>
                    )}
                    <div className="preview-content__bottom">
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>
                  {value === 1 ? (
                    <div className="preview-invitation__controls">
                      <button className="btn" disabled>
                        {lang === "en"
                          ? event?.reminder_accept_decline_template_en_decline_button_text
                          : event?.reminder_accept_decline_template_ar_decline_button_text}
                      </button>
                      <button className="btn">
                        {lang === "en"
                          ? event?.reminder_accept_decline_template_en_accept_button_text
                          : event?.reminder_accept_decline_template_ar_accept_button_text}
                      </button>
                      <button className="btn">
                        {lang === "en"
                          ? event?.reminder_accept_decline_template_en_location_button_text
                          : event?.reminder_accept_decline_template_ar_location_button_text}
                      </button>
                    </div>
                  ) : (
                    <div className="preview-invitation__controls">
                      <button className="btn btn-only">
                        {lang === "en"
                          ? event?.reminder_remind_only_template_en_location_button_text
                          : event?.reminder_remind_only_template_ar_location_button_text}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <button onClick={onSendReminder} className={`${styles.btn}`}>
             {t("buttons.sendNow")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewModal
