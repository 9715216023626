import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Button from "react-bootstrap/Button"
import { useAppDispatch } from "@/redux/store"
import { changePassword, clearError } from "@/redux/slices/auth/authSlice"
import Field from "./utils/Field"

type IForm = {
  password: string
  passwordConfirm: string
}

export const ChangePassword = () => {
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<IForm>({})

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (password.length < 8 && passwordConfirm.length < 8) {
      setError("password", {
        type: "custom",
        message: `${t("changePassword.errorLength")}`,
      })
      setError("passwordConfirm", {
        type: "custom",
        message: `${t("changePassword.errorLength")}`,
      })
    } else if (password.length < 8) {
      setError("password", {
        type: "custom",
        message: `${t("changePassword.errorLength")}`,
      })
    } else if (passwordConfirm.length < 8) {
      setError("passwordConfirm", {
        type: "custom",
        message: `${t("changePassword.errorLength")}`,
      })
    } else if (password !== passwordConfirm) {
      setError("password", {
        type: "custom",
        message: `${t("changePassword.errorMatch")}`,
      })
      setError("passwordConfirm", {
        type: "custom",
        message: `${t("changePassword.errorMatch")}`,
      })
    } else {
      dispatch(
        changePassword({
          email: new URLSearchParams(window.location.search).get("email"),
          password: password,
          password_confirm: passwordConfirm,
          token: new URLSearchParams(window.location.search).get("token"),
        }),
      ).then((res: any) => {
        if (res.error) {
          navigate("/password-failed")
          clearErrors()
          dispatch(clearError())
        } else {
          navigate("/password-success")
          clearErrors()
          dispatch(clearError())
        }
      })
    }
  }

  const onCancel = () => {
    navigate("/login")
  }

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <Field
        name="password"
        type="password"
        placeholder={t("changePassword.new")}
        value={password}
        onHandleChange={(value) => {
          clearErrors()
          setPassword(value)
        }}
        register={register}
        error={errors.password?.message}
      />

      <Field
        name="passwordConfirm"
        register={register}
        type="password"
        placeholder={t("changePassword.confirm")}
        value={passwordConfirm}
        onHandleChange={(value) => {
          clearErrors()
          setPasswordConfirm(value)
        }}
        error={errors.passwordConfirm?.message}
      />

      <div className="login__controls d-flex justify-content-end mt-sm-4 mt-1 gap-3">
        <Button onClick={onCancel} variant="btn btn-light px-3 py-2">
          {t("buttons.cancel")}
        </Button>

        <Button type="submit" variant="btn btn-dark px-4 py-2">
          {t("buttons.save")}
        </Button>
      </div>
    </form>
  )
}

export default ChangePassword
