import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  getContactsOnEvent,
  setLoading,
} from "../../../redux/slices/contacts/contactsSlices"
import { useAppDispatch } from "../../../redux/store"
import { updateInvites } from "../../../redux/slices/events/eventsSlice"
import styles from "./AddInvites.module.scss"

const AddInvites = ({ show, handleClose, filter, notify }: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const [value, setValue] = useState<number | null>(null)
  const [validation, setValidation] = useState({ invites_quantity: "" })

  const isEmpty = (val: any) => {
    if (!val) {
      return "empty"
    }
  }

  const onSubmit = () => {
    if (value && value >= 0 && value <= 10) {
      dispatch(updateInvites({ id: event_id, quantity: value })).then(
        (result: any) => {
          if (result.error) {
            handleClose()
            setValue(null)
          } else {
            dispatch(setLoading())
            dispatch(getContactsOnEvent(filter))
            handleClose()
            setValue(null)
            setValidation({ invites_quantity: "" })
            notify(t("notify.successfully"), false)
          }
        },
      )
    } else {
      setValidation({ invites_quantity: t("notify.value") })
      notify(t("notify.value"), true)
    }
  }

  const onHide = () => {
    handleClose()
    setValue(null)
    setValidation({ invites_quantity: "" })
  }

  return (
    <Modal
      className={`${styles.addPopup} add-invites__modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("event.inviteModal.title")}
          </h2>
          <span className={styles.text}>
            {t("event.inviteModal.description")}
          </span>

          <div className={styles.form}>
            <div
              className={`profile-input ${styles.input} ${
                validation.invites_quantity && "input-error"
              }`}
            >
              <input
                type="number"
                value={value ? value : ""}
                onChange={(event) => {
                  setValue(Number(event.target.value))
                }}
                className={isEmpty(value)}
              />

              <label htmlFor="" className={styles.label}>
                {t("placeholder.invitesQuantity")}
              </label>
 
              {validation.invites_quantity && (
                <span className="input-item__error error mt-1">
                  {validation.invites_quantity}
                </span>
              )}
            </div>
            <div className={styles.controls}>
              <span className="btn btn-simple" onClick={onHide}>
                {t("buttons.cancel")}
              </span>
              <button type="submit" className="btn btn-dark" onClick={onSubmit}>
                {t("buttons.add")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddInvites
