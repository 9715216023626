import UploadIcon from "@/assets/icons/Upload"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import { VscClose } from "react-icons/vsc"
import styles from "./QrDesignUploadedFile.module.scss"

const QrDesignUploadedFile = ({ setShowImageModal }: any) => {
  const { image } = useTypeSelector((state) => state.qr)

  return image ? (
    <div className="d-flex align-items-center justify-content-between gap-2 width-100">
      <div className={styles.content}>
        <UploadIcon className="icon-upload" />
        <span className={styles.name}>{image.name}</span>
        <div className={styles.size}>
          {(image?.size ? image?.size / 1024 : 0).toFixed(0)} KB
        </div>
      </div>
      <button
        className={`${styles.btn} btn btn-sm`}
        onClick={() => setShowImageModal(true)}
      >
        <VscClose className={styles.close} />
      </button>
    </div>
  ) : (
    ""
  )
}

export default QrDesignUploadedFile
