import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { checkCharge } from "@/redux/slices/events/eventsSlice"
import { setLoading } from "@/redux/slices/auth/authSlice"
import Preloader from "@/components/utils/Preloader/Preloader"
import "./ResultPage.scss"

const ResultPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { success } = useAppSelector((state) => state.events)

  useEffect(() => {
    dispatch(setLoading())
    setTimeout(
      () =>
        dispatch(
          checkCharge({
            charge_id: new URLSearchParams(window.location.search).get(
              "tap_id",
            ),
          }),
        ),
      5000,
    )
  }, [dispatch])

  return (
    <>
      <div className="result">
        <div className="container">
          {success === "" ? (
            <Preloader />
          ) : success === "false" ? (
            <div className="result-main">
              <div className="result-left">
                <h2 className="caption-two result-title">
                  {t("result.title")}
                </h2>
                <h4 className="result-description">{t("result.desc")}</h4>
                <button
                  className="btn btn-dark result-button"
                  onClick={() => navigate(`/credits`)}
                >
                  {t("buttons.tryAgain")}
                </button>
              </div>
              <div className="result-right">
                <img
                  className="result-picture"
                  src="../../../images/payment-failed.png"
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="result-main">
              <div className="result-left">
                <h2 className="caption-two result-title">
                  {t("result.titleSuccess")}
                </h2>
                <div className="result-buttons">
                  <span
                    className="btn btn-simple"
                    onClick={() => navigate(`/profile`)}
                  >
                    {t("result.profile")}
                  </span>
                  <button
                    className="btn btn-dark"
                    onClick={() => navigate(`/`)}
                  >
                    {t("result.toHome")}
                  </button>
                </div>
              </div>
              <div className="result-right">
                <img
                  className="result-picture"
                  src="../../../images/payment-successful.png"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ResultPage
