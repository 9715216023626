import { actions } from "@/redux/slices/qr/qrSlice"
import { useAppDispatch } from "@/redux/store"
import { bindActionCreators } from "@reduxjs/toolkit"
const rootActions = {
  ...actions,
}

export const useActions = () => {
  const dispatch = useAppDispatch()
  return bindActionCreators(rootActions, dispatch)
}
