import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getContactsOnEvent,
  setCurrentPage,
} from "../../redux/slices/contacts/contactsSlices"
import {
  IconArrowLeft,
  IconBalance,
  IconBell,
  IconBtnCreate,
  IconCheck,
  IconProfileInfo,
  IconSearch,
} from "../../components/utils/Icons/CustomIcons"
import GuestsList from "../../components/ThankMessage/GuestsList"
import {
  getEvent,
  getThankMessage,
} from "../../redux/slices/events/eventsSlice"
import Paginator from "../../components/utils/Pagination/Pagination"
import GuestsFilter from "../../components/ThankMessage/GuestFilter"
import GuestsSearch from "../../components/ThankMessage/GuestsSearch"
import PreviewModal from "../../components/ThankMessage/PreviewModal/PreviewModal"

const ThankMessagePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const { current_page, total_pages, limit } = useAppSelector(
    (state) => state.contacts,
  )
  const { balance } = useAppSelector((state) => state.auth)
  const event = useAppSelector((state) => state.events.currentEvent)
  const { data_on_event } = useAppSelector((state) => state.contacts)
  const [name, setName] = useState("")
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // const [dataLoadMore, setDataLoadMore] = useState([])
  const [filter, setFilter] = useState({
    sort: "",
    order: "",
    searchValue: "",
    reminderNumber: "",
    statusNumber: "0,1,2,5,6",
    id: Number(event_id),
  })
  const [checked, setChecked] = useState([])
  const [allGuestsCheked, setAllGuestsChecked] = useState([])
  const [checkedFilter, setCheckedFilter] = useState("")
  const [checkedReminder, setCheckedReminder] = useState("")

  const notify = (text: string, color: boolean) => {
    color
      ? toast.warning(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  useEffect(() => {
    dispatch(getEvent(Number(event_id)))
  }, [dispatch, event_id])

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
    dispatch(getContactsOnEvent(filter))
  }

  const getBalance = () => {
    let sum = 0
    data_on_event.forEach((contact) => {
      if (checked.some((id) => id == contact.id)) {
        contact.free_reminder === false ? (sum += 1) : sum
      }
    })
    return sum
  }

  const onSend = () => {
    if (checked.length > 0 && getBalance() <= balance) {
      dispatch(getThankMessage(Number(event_id)))
      setShowModal(true)
      const nameObj =
        data_on_event && data_on_event.filter((item) => item.id === checked[0])
      setName(
        `${nameObj[0].suffix} ${nameObj[0].first_name} ${nameObj[0].last_name}`,
      )
    } else if (checked.length > 0 && getBalance() > balance) {
      notify(t("notify.topUp"), true)
    } else {
      notify(t("notify.select"), true)
    }
  }
  return (
    <div className="thanks">
      <div className="container">
        <div className="thanks-page__link">
          <Link
            className="d-flex align-items-center thanks-page__link-container"
            to={`/${event_id}`}
          >
            <IconArrowLeft />
            <span className="thanks-page__link-text">
              {t("buttons.back")} {event?.name}
            </span>
          </Link>
        </div>
        <div className="thanks-title">
          <h2 className="caption-two">{t("thank.titlePage")}</h2>
          <Button className="btn btn-dark thanks-title__btn" onClick={onSend}>
            {t("buttons.send")}
          </Button>
        </div>

        <div className="thanks-row">
          <div className="thanks-col thanks-col--balance bl-default">
            <div className="thanks-left">
              <h2 className="caption caption-three">
                {t("thank.availableBalance")}
              </h2>

              <div className="thanks-info">
                <IconBalance />
                <span className="thanks-balance__value">
                  {balance} {t("thank.credits")}
                </span>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="thanks-tooltip" id="tooltipCredits">
                      {t("thank.tooltip.credits")}
                    </Tooltip>
                  }
                >
                  <div className="thanks-tooltip__content">
                    <IconProfileInfo color="dark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <Button className="btn btn-dark" onClick={() => navigate('/credits')}>
              <IconBtnCreate />
              {t("buttons.topUP")}
            </Button>
          </div>

          <div className="thanks-col thanks-col--plan bl-default">
            <div className="thanks-left">
              <h2 className="caption caption-three">
                {t("thank.freeReminder")}
              </h2>

              <div className="thanks-info">
                <IconCheck />

                <span className="thanks-balance__value">
                  {t("thank.useCredits")}
                </span>
              </div>
            </div>

            <IconBell />
          </div>
          <div className="thanks-col thanks-col--content bl-default">
            <div className="thanks-col__head">
              <h2 className="caption caption-three">{t("thank.guests")}</h2>
              <div className="thanks-guests__buttons">
                {!show ? (
                  <button
                    className="d-flex justify-content-center align-items-center event-page__btn"
                    onClick={() => setShow(true)}
                  >
                    <IconSearch />
                  </button>
                ) : (
                  <GuestsSearch
                    filter={filter}
                    setFilter={setFilter}
                    name={"desktop"}
                  />
                )}
                <GuestsFilter
                  filter={filter}
                  setFilter={setFilter}
                  checkedFilter={checkedFilter}
                  setCheckedFilter={setCheckedFilter}
                  checkedReminder={checkedReminder}
                  setCheckedReminder={setCheckedReminder}
                />
              </div>
            </div>
            <GuestsSearch
              filter={filter}
              setFilter={setFilter}
              name={"mobile"}
            />
            <GuestsList
              filter={filter}
              setFilter={setFilter}
              width={width}
              limit={limit}
              checked={checked}
              setChecked={setChecked}
              allGuestsChecked={allGuestsCheked}
              setAllGuestsChecked={setAllGuestsChecked}
            />
          </div>
        </div>
      </div>
      <PreviewModal
        show={showModal}
        setShow={setShowModal}
        checked={checked}
        setChecked={setChecked}
        setAllGuestsChecked={setAllGuestsChecked}
        filter={filter}
        name={name}
        setName={setName}
      />
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
      <div className="event-paginator">
        {total_pages !== 0 && (
          <Paginator
            total_pages={total_pages}
            current_page={current_page}
            onPageChanged={onPageChanged}
          />
        )}
      </div>
      <div className="container">
        <button
          type="button"
          className="btn btnloadMore d-lg-none d-sm-flex btn btn-primary event-loadmore"
          onClick={() => {
            // setDataLoadMore(data_on_event)
            dispatch(
              setCurrentPage(
                current_page === total_pages ? 1 : current_page + 1,
              ),
            )
            dispatch(getContactsOnEvent(filter))
          }}
        >
          {t("buttons.loadMore")}
        </button>
      </div>
    </div>
  )
}

export default ThankMessagePage
