import { Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import { sendWAInvite } from "../../../redux/slices/events/eventsSlice"
import styles from "./SendInviteModal.module.scss"

const SendInviteModal = ({
  show,
  setShow,
  title,
  type,
  setChecked,
  setAllGuestsChecked,
  notify,
  checked,
}: any) => {
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const { data_on_event } = useAppSelector((state) => state.contacts)

  const onSendWAInvite = () => {
    const arr = data_on_event
      .filter(
        (item) =>
          checked.includes(item.id) && item.can_send_invitation === true,
      )
      .map((item) => item.id)
    arr.length > 0
      ? dispatch(
          sendWAInvite({ eventId: Number(event_id), contactIds: arr }),
        ).then((res: any) => {
          console.log(res)
          notify(t("notify.sent"), false)
        })
      : notify(t("notify.notSent"), true)
    setChecked([])
    setAllGuestsChecked([])
    setShow(false)
  }

  const onCancel = () => {
    setChecked([])
    setAllGuestsChecked([])
    setShow(false)
  }

  return (
    <Modal
      className={`${styles.addPopup} ${
        type === "delete"
          ? "event-page__delete-modal"
          : "event-page__invite-modal"
      }`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{title}</h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onCancel}>
              {t("buttons.cancel")}
            </span>
            <button onClick={onSendWAInvite} className="btn btn-dark">
              {t("buttons.continue")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SendInviteModal
