import axios from "axios"
import endpoints from "./endpoints"

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_BASE_URL,
})

const urlsSkipAuth = [
  endpoints.AUTH.LOGIN,
  endpoints.AUTH.PHONESTEP1,
  endpoints.AUTH.PHONESTEP2,
  endpoints.AUTH.LOGOUT,
]

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && urlsSkipAuth.includes(config.url)) {
    return config
  }

  const accessToken = localStorage.getItem("token") || null

  if (accessToken) {
    const autharization = `Bearer ${accessToken}`

    config.headers = {
      ...config.headers,
      authorization: autharization,
    }
  }

  return config
})

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && config.url === endpoints.EVENTS.PREVIEW) {
    config.headers = {
      ...config.headers,
      Accept: "application/x..v2+json",
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.GET)) {
    if (config.data) {
      return config
    }

    config.headers = {
      ...config.headers,
      Accept: "application/x..v2+json",
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.GET_EVENT)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.SEND_QR)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes("mass-update-quantity")) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.SEND_INVITATION_ALL)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.CONTACTS.UP)) {
    if (config.data) {
      return config
    }

    config.headers = {
      ...config.headers,
      Accept: "application/x..v2+json",
    }
  }

  if (config.url && config.url === endpoints.CONTACTS.IMPORT) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
      // "Content-Language": "ar, en",
    }
  }

  if (config.url && config.url === endpoints.CONTACTS.IMPORT_GUEST) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
      // "Content-Language": "ar, en",
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.LOAD_IMG)) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
    }
  }

  if (config.url && config.url.includes(endpoints.CONTACTS.DELETE_GUEST)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.CONTACTS.ADD_GUEST)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.CONTACTS.IMPORT_FROM_CONTACTS)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.CONTACTS.UPDATE_GUEST)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.DELETE)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.SEND_WA_INVITE)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.UPDATE_INVITE)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.GET_BALANCE)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.SEND_REMINDER)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.SEND_TEST_INVITATION)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.EVENTS.GET_TEST_INVITATION)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url.includes('/contacts?') && config.url.includes('/events/')) {
    config.headers = {
      ...config.headers,
      Accept: "application/x..v2+json",
    }
  }

  if (config.url && config.url.includes(endpoints.EVENTS.GET_THANK_MESSAGE)) {
    config.headers = {
      ...config.headers,
      Accept: "application/x..v2+json",
    }
  }

  if (config.url && config.url.includes(endpoints.MESSAGES.MARK_AS_READ)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.MESSAGES.GET)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.MESSAGES.GET_CONVERSATION)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  if (config.url && config.url === (endpoints.MESSAGES.SEND_MESSAGE)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
    }
  }

  return config
})

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && config.url.includes(endpoints.EVENTS.QR_DESIGN)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
      Accept: "application/x..v2+json",
    }
  }
  return config
})

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && config.url.includes(endpoints.EVENTS.UPDATE_QR_DESIGN)) {
    const accessToken = localStorage.getItem("token") || null
    config.headers = {
      "Authorization": `Bearer ${accessToken}`,
      Accept: "application/x..v2+json",
    }
  }
  return config
})

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && config.url.includes(endpoints.EVENTS.UPLOAD_QR_IMAGE)) {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
      Accept: "application/x..v2+json",
    }
  }
  return config
})
