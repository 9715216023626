import { ChangeEventHandler, HTMLInputTypeAttribute } from "react"
import styles from "./QrDesignInput.module.scss"

type InputProps = {
  type?: HTMLInputTypeAttribute
  value?: HTMLInputTypeAttribute
  placeholder?: HTMLInputTypeAttribute
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

const QrDesignInput = ({ type, value, onChange, placeholder, disabled }: InputProps) => {
  return (
    <input
      type={type ?? "text"}
      className={styles.input}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default QrDesignInput
