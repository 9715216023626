import { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import QrDesignUploadedFile from "@/components/QrDesign/UI/QrDesignUploadedFile"
import CropModal from "@/components/QrDesign/cropModal/CropModal"
import QrDesignFileLoader from "@/components/QrDesign/file-load/QrDesignFileLoader"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import { PiCrownFill } from "react-icons/pi"
import {
  getEvent,
  getQrDesign,
  getQrImage,
  setLoading,
} from "@/redux/slices/events/eventsSlice"
import { useParams } from "react-router-dom"
import { useActions } from "@/hooks/useActions"
import Preloader from "@/components/utils/Preloader/Preloader"
import DeleteImageModal from "@/components/QrDesign/DeleteImageModal/DeleteImageModal"
import QrDesignEditor from "./QrDesignEditor/QrDesignEditor"
import styles from "./QrDesignPage.module.scss"

const QrDesignPage = () => {
  const { t } = useTranslation()
  const { image, croppedImagePath } = useTypeSelector((state) => state.qr)
  const { currentEvent, isLoading } = useAppSelector((state) => state.events)
  const { deleteImage } = useActions()
  const [showImageModal, setShowImageModal] = useState(false)
  const [qrDesignName, setQrDesignName] = useState("")
  const [savePreview, setSavePreview] = useState(false)
  const [edit, setEdit] = useState(false)
  const [file, setFile] = useState<Blob | MediaSource | null>(null)
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  useEffect(() => {
    event_id && dispatch(getEvent(Number(event_id)))
  }, [event_id])

  useEffect(() => {
    return () => {
      deleteImage()
    }
  }, [])

  useEffect(() => {
    event_id &&
      currentEvent &&
      currentEvent.qr_code_design_id !== null &&
      setEdit(true)
    if (
      currentEvent?.qr_code_design_id !== null &&
      currentEvent?.qr_code_design_id !== undefined
    ) {
      dispatch(setLoading())
      dispatch(getQrDesign(currentEvent?.qr_code_design_id)).then(
        (res: any) => {
          const urlParams = new URLSearchParams(
            res.payload.data.image_url.split("?")[1],
          )
          dispatch(
            getQrImage({
              id: res.payload.data.id,
              hash: urlParams.get("hash"),
            }),
          )
          setQrDesignName(res.payload.data.name)
        },
      )
    }
    // if (currentEvent && currentEvent.qr_code_design_id !== null) {
    //   setSavePreview(true)
    // }
  }, [currentEvent])

  return (
    <div className="qrCreatePage">
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="container">
          <div
            className="d-flex align-items-center gap-2"
            style={{ marginBottom: "24px" }}
          >
            <PiCrownFill color="#F9B81A" size={32} />
            <h2 className="caption-two">
              {edit ? t("qr.edit") : t("qr.create")}
            </h2>
          </div>
          <div className={styles.main_block}>
            <h4
              className="caption-three"
              style={{ marginBottom: window.innerWidth > 767 ? "24px" : "0" }}
            >
              {t("qr.title")}
            </h4>
            <QrDesignFileLoader
              setQrDesignName={setQrDesignName}
              qrDesignName={qrDesignName}
              croppedImagePath={croppedImagePath}
              setFile={setFile}
              savePreview={savePreview}
            />
            {croppedImagePath && image && (
              <>
                {!savePreview && (
                  <div className={styles.uploaded_file}>
                    <h4 className="caption-three">{t("qr.editor")}</h4>
                    <QrDesignUploadedFile
                      setShowImageModal={setShowImageModal}
                    />
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center flex-column w-100">
                  <QrDesignEditor
                    setSavePreview={setSavePreview}
                    savePreview={savePreview}
                    qrDesignName={qrDesignName}
                    setQrDesignName={setQrDesignName}
                    file={file}
                    setEdit={setEdit}
                    edit={edit}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <DeleteImageModal show={showImageModal} setShow={setShowImageModal} />
      <CropModal />
      <Toaster />
    </div>
  )
}

export default QrDesignPage
