import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { IconClose, IconFailed } from "../../utils/Icons/CustomIcons"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { registerAnswer } from "@/redux/slices/events/eventsSlice"
import "./DeclineModal.scss"

const DeclineModal = ({ show, setShow }: { show: boolean; setShow: any }) => {
  const { t } = useTranslation()
  const { event_by_hash } = useAppSelector((state) => state.events)
  const dispatch = useAppDispatch()
  const [value, setValue] = useState({ name: "", phone: "", email: "" })

  const [error, setError] = useState<any>({
    name: "",
    phone: "",
  })

  const onSubmit = () => {
    if (value.name === "" && value.phone === "") {
      setError({
        ...error,
        name: t("errors.fieldRequired"),
        phone: t("errors.fieldRequired"),
      })
    } else if (value.name === "") {
      setError({
        ...error,
        name: t("errors.fieldRequired"),
        phone: "",
      })
    } else if (value.phone === "") {
      setError({
        ...error,
        name: "",
        phone: t("errors.fieldRequired"),
      })
    } else {
      setError({ name: "", phone: "" })
      dispatch(
        registerAnswer({
          open_event_hash: event_by_hash.open_event_hash,
          accepted: false,
          first_name: value.name,
          phone_number: value.phone,
          email: value.email,
          number_of_guests: 0,
        }),
      ).then(() => {
        setValue({
          name: "",
          phone: "",
          email: "",
        })
        setError({
          name: "",
          phone: "",
        })
        setShow(false)
      })
    }
  }

  const onHide = () => {
    setValue({
      name: "",
      phone: "",
      email: "",
    })
    setError({
      name: "",
      phone: "",
    })
    setShow(false)
  }

  return (
    <Modal className={`decline-modal`} show={show} onHide={onHide}>
      <Modal.Body>
        <div className="decline-container">
          <div className="decline">
            <div className="decline-inner">
              <div className="decline-main__header">
                <IconFailed />
                <span className="decline-main__header-title">
                  {t("register.decline.title")}
                </span>
                <div className="d-flex align-items-center" onClick={onHide}>
                  <IconClose className="decline-main__header-close" />
                </div>
              </div>
              <section className="decline-main">
                <div className="decline-main__form">
                  <div className="decline-main__card">
                    <p className="decline-main__card-description">
                      {t("register.decline.description")}
                    </p>
                    <input
                      className={`decline-main__card-input ${
                        error?.name && "error"
                      }`}
                      value={value.name}
                      placeholder={t("placeholder.enterFirstName")}
                      onChange={(e) =>
                        setValue({ ...value, name: e.target.value })
                      }
                    />
                    <p className="decline-main__card-label">
                      {t("register.accept.name")}
                      {error?.name && (
                        <div className="decline-main__card-error">
                          {t("register.error1")}
                        </div>
                      )}
                    </p>
                    <input
                      className={`decline-main__card-input ${
                        error?.phone && "error"
                      }`}
                      value={value.phone}
                      placeholder={t("placeholder.enterPhone")}
                      type="number"
                      onChange={(e) =>
                        setValue({ ...value, phone: e.target.value })
                      }
                    />
                    <p className="decline-main__card-label">
                      {t("register.accept.phone")}
                      {error?.phone && (
                        <div className="decline-main__card-error">
                          {t("register.error1")}
                        </div>
                      )}
                    </p>
                    <input
                      className="decline-main__card-input"
                      value={value.email}
                      placeholder={t("placeholder.enterEmail")}
                      onChange={(e) =>
                        setValue({ ...value, email: e.target.value })
                      }
                    />
                    <p className="decline-main__card-label">
                      {t("register.accept.email")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-dark" onClick={() => onSubmit()}>
                      {t("buttons.send")}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeclineModal
