import { useEffect, useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { t } from "i18next"
import { IconArrowLeft } from "../../utils/Icons/CustomIcons"
import { useAppDispatch } from "../../../redux/store"
import {
  testTheInvitation,
  validateOTP,
} from "../../../redux/slices/events/eventsSlice"
import { getBalance } from "../../../api/events"
import "react-phone-number-input/style.css"
import styles from "./EnterCodeModal.module.scss"

const EnterCodeModal = ({
  show,
  setShow,
  showVerifyModal,
  setShowVerifyModal,
  value,
  setValue,
  setShowTestModal,
  notify,
  minutes,
  seconds,
  restart,
}: any) => {
  const dispatch = useAppDispatch()
  const [firstDigit, setFirstDigit] = useState<string>("")
  const [secondDigit, setSecondDigit] = useState<string>("")
  const [thirdDigit, setThirdDigit] = useState<string>("")
  const [fourthDigit, setFourthDigit] = useState<string>("")
  const [fifthDigit, setFifthDigit] = useState<string>("")
  const [sixthDigit, setSixthDigit] = useState<string>("")
  const [seventhDigit, setSeventhDigit] = useState<string>("")
  const [eighthDigit, setEighthDigit] = useState<string>("")
  const inputRef = useRef(null)
  const inputRefTwo = useRef(null)
  const inputRefThree = useRef(null)
  const inputRefFour = useRef(null)
  const inputRefFive = useRef(null)
  const inputRefSix = useRef(null)
  const inputRefSeven = useRef(null)
  const inputRefEight = useRef(null)

  function checkPress(e: any, nextRef: any, prevRef: any) {
    if (e.target.value.length === 1 && e.target.value !== eighthDigit) {
      nextRef.focus()
    }

    if (e.keyCode === 8 && e.target.value !== firstDigit) {
      prevRef.focus()
    }
  }

  const onPaste = (e: any) => {
    let numberArr = e.clipboardData.getData("Text").split("")
    setFirstDigit(numberArr[0])
    setSecondDigit(numberArr[1])
    setThirdDigit(numberArr[2])
    setFourthDigit(numberArr[3])
    setFifthDigit(numberArr[4])
    setSixthDigit(numberArr[5])
    setSeventhDigit(numberArr[6])
    setEighthDigit(numberArr[7])
  }

  const getResult = (result: number) => {
    switch (result) {
      case 1:
        return t("enterCodeModal.result.incorrect")
        break
      case 2:
        return t("enterCodeModal.result.no")
        break
      case 3:
        return t("enterCodeModal.result.namy")
        break
      case 4:
        return t("enterCodeModal.result.success")
        break
      case 5:
        return t("enterCodeModal.result.expired")
        break
      default:
        return ""
        break
    }
  }

  useEffect(() => {
    const code = [
      firstDigit,
      secondDigit,
      thirdDigit,
      fourthDigit,
      fifthDigit,
      sixthDigit,
      seventhDigit,
      eighthDigit,
    ]

    !code.includes("") &&
      code.length === 8 &&
      dispatch(validateOTP(code.join(""))).then((result) => {
        if (result.payload.result === 4) {
          setShow(false)
          setShowVerifyModal(true)
          dispatch(getBalance)
        } else {
          notify(getResult(result.payload.result), true)
        }
      })
  }, [
    firstDigit,
    secondDigit,
    thirdDigit,
    fourthDigit,
    fifthDigit,
    sixthDigit,
    seventhDigit,
    eighthDigit,
  ])

  const goBack = () => {
    setShow(false)
    setShowTestModal(true)
  }

  const sendAgain = () => {
    dispatch(testTheInvitation(value))
    const time = new Date()
    time.setSeconds(time.getSeconds() + 180)
    restart(time)
    setFirstDigit("")
    setSecondDigit("")
    setThirdDigit("")
    setFourthDigit("")
    setFifthDigit("")
    setSixthDigit("")
    setSeventhDigit("")
    setEighthDigit("")
  }

  const onHide = () => {
    setFirstDigit("")
    setSecondDigit("")
    setThirdDigit("")
    setFourthDigit("")
    setFifthDigit("")
    setSixthDigit("")
    setSeventhDigit("")
    setEighthDigit("")
    setValue(null)
    setShow(false)
  }

  return (
    <Modal
      className={`${styles.addPopup} event-page__enter-modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <div className={`${styles.return}`}>
            <IconArrowLeft />
            <span className={`${styles.returnText}`} onClick={goBack}>
              {t("enterCodeModal.back")}
            </span>
          </div>
          <h2 className={`caption ${styles.caption}`}>
            {t("enterCodeModal.title")}
          </h2>
          <span className={styles.text}>
            {t("enterCodeModal.text")} +
            {value &&
              value.substr(0, 3) + "********" + value.substr(length - 2, 2)}
          </span>
          <form className={styles.form}>
            <input
              type="text"
              onPaste={(e) => onPaste(e)}
              ref={inputRef}
              onKeyUp={(e) =>
                checkPress(e, inputRefTwo.current, inputRef.current)
              }
              value={firstDigit}
              maxLength={1}
              className={`${styles.input} ${
                firstDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setFirstDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefTwo}
              onKeyUp={(e) =>
                checkPress(e, inputRefThree.current, inputRef.current)
              }
              maxLength={1}
              value={secondDigit}
              className={`${styles.input} ${
                secondDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setSecondDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefThree}
              onKeyUp={(e) =>
                checkPress(e, inputRefFour.current, inputRefTwo.current)
              }
              value={thirdDigit}
              maxLength={1}
              className={`${styles.input} ${
                thirdDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setThirdDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefFour}
              onKeyUp={(e) =>
                checkPress(e, inputRefFive.current, inputRefThree.current)
              }
              value={fourthDigit}
              maxLength={1}
              className={`${styles.input} ${
                fourthDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setFourthDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefFive}
              onKeyUp={(e) =>
                checkPress(e, inputRefSix.current, inputRefFour.current)
              }
              value={fifthDigit}
              maxLength={1}
              className={`${styles.input} ${
                fifthDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setFifthDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefSix}
              onKeyUp={(e) =>
                checkPress(e, inputRefSeven.current, inputRefFive.current)
              }
              value={sixthDigit}
              maxLength={1}
              className={`${styles.input} ${
                sixthDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setSixthDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefSeven}
              onKeyUp={(e) =>
                checkPress(e, inputRefEight.current, inputRefSix.current)
              }
              value={seventhDigit}
              maxLength={1}
              className={`${styles.input} ${
                seventhDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setSeventhDigit(e.target.value)}
            />
            <input
              type="text"
              ref={inputRefEight}
              onKeyUp={(e) =>
                checkPress(e, inputRefEight.current, inputRefSeven.current)
              }
              value={eighthDigit}
              maxLength={1}
              className={`${styles.input} ${
                eighthDigit !== "" && styles.inputFilled
              }`}
              onChange={(e) => setEighthDigit(e.target.value)}
            />
          </form>
          {minutes === 0 && seconds === 0 ? (
            <div className={styles.timer}>
              <span className={styles.redText}>
                {t("enterCodeModal.expire")}
              </span>
            </div>
          ) : (
            <div className={styles.timer}>
              <span className={styles.smallText}>
                {t("enterCodeModal.willExpire")}
              </span>
              <span className={styles.smallText}>
                {`0${minutes}`}:{seconds === 0 ? `${seconds}0` : seconds}
              </span>
            </div>
          )}
          <div className={styles.sendAgain}>
            <span className={styles.smallText}>
              {t("enterCodeModal.notRecieve")}
            </span>
            <button className={styles.textUnderlined} onClick={sendAgain}>
              {t("enterCodeModal.send")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EnterCodeModal
