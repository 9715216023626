const Inloader = () => {
  return (
    <div className="inloader">
      <div className="loader">
        <div className="arc"></div>
      </div>
    </div>
  )
}

export default Inloader
