import { Dropdown } from "react-bootstrap"
import { useParams } from "react-router-dom"
import {
  IconBtnCreate,
  IconChat,
  IconNotifications,
  IconOptions,
  IconReload,
} from "../utils/Icons/CustomIcons"
import {t} from "i18next";

type Props = {
  show: boolean
  setShow: any
  showSendQR: boolean
  setShowSendQR: any
  id: string | number | undefined
}

const ActionsPopup = ({ show, setShow, showSendQR, setShowSendQR, id }: Props) => {
  const {event_id} = useParams()
  return (
    <Dropdown className="dropdown-block dropdown--not-arrow dropdown-filter actions-popup">
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent event-page__btn">
        <IconOptions />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setShow(!show)}>
          <IconBtnCreate />
          <span className="dropdown-text">{t("event.actionsDropdown.addInvites")}</span>
        </Dropdown.Item>
        <Dropdown.Item href={`/${id}/thank-message`}>
          <IconChat />
          <span className="dropdown-text">{t("event.actionsDropdown.sendThankYouMessage")}</span>
        </Dropdown.Item>
        <Dropdown.Item href={`/${event_id}/reminders`}>
          <IconNotifications />
          <span className="dropdown-text">{t("event.actionsDropdown.sendReminder")}</span>
        </Dropdown.Item>
        {/*<Dropdown.Item onClick={() => setShowSendQR(!showSendQR)}>*/}
        {/*  <IconReload />*/}
        {/*  <span className="dropdown-text">{t("event.actionsDropdown.sendUndeliveredQRCodes")}</span>*/}
        {/*</Dropdown.Item>*/}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionsPopup
