import { Dropdown } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import {
  deleteGuest,
  getContactsOnEvent,
  setLoading,
} from "../../redux/slices/contacts/contactsSlices"
import { sendWAInvite } from "../../redux/slices/events/eventsSlice"
import { useAppDispatch } from "../../redux/store"
import { sendInvitation } from "../../api/events"
import {
  IconContactDeleteRed,
  IconContactsChange,
  IconCopyDoc,
  IconEnvelope,
  IconOptions,
  IconWhatsapp,
} from "../utils/Icons/CustomIcons"

const GuestActionsPopup = ({ editPopupOpen, guest, filter, notify }: any) => {
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const onDeleteGuest = () => {
    dispatch(
      deleteGuest({ eventId: event_id, guestId: [{ contact_id: guest.id }] }),
    ).then((result: any) => {
      if (result.error) {
        console.log(result)
      } else {
        dispatch(setLoading())
        dispatch(getContactsOnEvent(filter))
        notify(t("notify.deletedSuccessfully"), false)
      }
    })
  }

  const onSendWAInvite = () => {
    if (guest.can_send_invitation) {
      dispatch(
        sendWAInvite({ eventId: Number(event_id), contactIds: [guest.id] }),
      ).then(() => {
        notify(t("notify.messagesSent"), false)
      })
    } else {
      notify(t("notify.messagesSent"), true)
    }
  }

  const onCopyInvitationMessage = () => {
    navigator.clipboard.writeText(guest.message)
    notify(
      t("guests.actionsDropdown.copyInvitationMessage.resultNotifyText"),
      false,
    )
  }

  return (
    <Dropdown className="dropdown-block dropdown--not-arrow dropdown-filter guest-actions-popup">
      <Dropdown.Toggle className="dropdown-block-text border-0 event-page__btn">
        <IconOptions />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-my">
        <Dropdown.Item onClick={onSendWAInvite}>
          <div>
            <IconWhatsapp />
          </div>
          <span className="dropdown-text">
            {t("guests.actionsDropdown.sendWhatsappInvite")}
          </span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onCopyInvitationMessage}>
          <div>
            <IconCopyDoc />
          </div>
          <span className="dropdown-text">
            {t("guests.actionsDropdown.copyInvitationMessage.title")}
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          href={sendInvitation(guest.phone, guest.message)}
          target="_blank"
        >
          <div>
            <IconEnvelope />{" "}
          </div>
          <span className="dropdown-text">
            {t("guests.actionsDropdown.sendInviteManually")}
          </span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => editPopupOpen(guest)}>
          <div>
            <IconContactsChange />
          </div>
          <span className="dropdown-text">
            {t("guests.actionsDropdown.edit")}
          </span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onDeleteGuest}>
          <div>
            <IconContactDeleteRed />
          </div>
          <span className="dropdown-text">
            {t("guests.actionsDropdown.delete")}
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestActionsPopup
