import Switch from "react-switch"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import QrDesignColorPeek from "@/components/QrDesign/UI/color-peeker/QrDesignColorPeek"
import QrDesignSelect from "@/components/QrDesign/UI/select/QrDesignSelect"
import styles from "./QrDesignTextEditor.module.scss"

const alignOptions = [
  {
    value: "left",
    label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
  },
  {
    value: "center",
    label: <img src="/vector/QrDesign/alignt_format_center.svg" />,
  },
  {
    value: "right",
    label: <img src="/vector/QrDesign/alignt_format_right.svg" />,
  },
]


type Props = {
  fontValue: {
    label: string
    value: string
  }
  alignValue: any
  color: string
  setColor: any
  colorText: string
  setColorText: any
  bold: boolean
  italic: boolean
  underlined: boolean
  showOthers: boolean
  savePreview: boolean
  setFontValue: any
  setAlignValue: any
  setItalic: any
  setUnderlined: any
  setBold: any
  setShow: any
  show: boolean
}

const QrDesignTextEditor = ({
  fontValue,
  alignValue,
  color,
  setColor,
  colorText,
  setColorText,
  bold,
  italic,
  underlined,
  showOthers,
  savePreview,
  setFontValue,
  setAlignValue,
  setItalic,
  setUnderlined,
  setBold,
  setShow,
  show,
}: Props) => {
  const { t } = useTranslation()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [hide, setHide] = useState({
    font: false,
    align: false,
    color: false,
    text: false,
  })

  const fontOptions = [
    {
      label: "Montserrat",
      value: "Montserrat",
    },
    {
      label: "Inter",
      value: "Inter",
    },
    {
      label: "Roboto",
      value: "Roboto",
    },
    {
      label: "SF Pro Display",
      value: "SF Pro Display",
    },
    {
      label: "Barlow",
      value: "Barlow",
    },
  ]

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  const handleChange = () => {
    setShow(!show)
  }
  return (
    <div className="d-flex flex-column">
      {!savePreview ? (
        <div
          className={styles.main_block}
          style={{ position: "relative", zIndex: 3 }}
        >
          <div className={styles.selects}>
            <QrDesignSelect
              type="font"
              hide={hide}
              setHide={setHide}
              options={fontOptions}
              width={window.innerWidth > 991 ? "120px" : "100px"}
              controlWidth={window.innerWidth > 991 ? "83px" : "69px"}
              currentValue={fontValue}
              setCurrentValue={setFontValue}
              showOthers={showOthers}
            />
            <QrDesignSelect
              type="align"
              hide={hide}
              setHide={setHide}
              options={alignOptions}
              width={window.innerWidth > 991 ? "50px" : "36px"}
              controlWidth={window.innerWidth > 991 ? "34px" : "22px"}
              currentValue={alignValue}
              setCurrentValue={setAlignValue}
              showOthers={showOthers}
            />
            <QrDesignColorPeek
              hide={hide}
              setHide={setHide}
              selectedOption={color}
              setSelectedOption={setColor}
              type={"color"}
              showOthers={showOthers}
            />
            <QrDesignColorPeek
              hide={hide}
              setHide={setHide}
              selectedOption={colorText}
              setSelectedOption={setColorText}
              type={"text"}
              showOthers={showOthers}
            />
          </div>
          <div className={styles.styles}>
            <img
              src="/vector/QrDesign/bold.svg"
              alt="bold"
              onClick={() => setBold(!bold)}
              style={{
                display: showOthers ? "block" : "none",
                width: window.innerWidth < 992 ? "16px" : "24px",
                height: window.innerWidth < 992 ? "16px" : "24px",
              }}
            />
            <img
              src="/vector/QrDesign/underlined.svg"
              alt="underlined"
              onClick={() => setUnderlined(!underlined)}
              style={{
                display: showOthers ? "block" : "none",
                width: window.innerWidth < 992 ? "16px" : "24px",
                height: window.innerWidth < 992 ? "16px" : "24px",
              }}
            />
            <img
              src="/vector/QrDesign/italic.svg"
              alt="italic"
              onClick={() => setItalic(!italic)}
              style={{
                display: showOthers ? "block" : "none",
                width: window.innerWidth < 992 ? "16px" : "24px",
                height: window.innerWidth < 992 ? "16px" : "24px",
              }}
            />
            <label
              htmlFor="material-switch"
              className={styles.form}
              style={{ display: showOthers ? "flex" : "none" }}
            >
              <span className={styles.check}>{t("qr.show")}</span>
              <Switch
                onChange={handleChange}
                checked={show}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#000000"
                boxShadow="0px"
                activeBoxShadow="0px"
                width={width > 767 ? 48 : 32}
                height={width > 767 ? 24 : 16}
                handleDiameter={width > 767 ? 20 : 12}
                className="qr-design-switch"
              />
            </label>
          </div>
        </div>
      ) : (
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: window.innerWidth > 991 ? "24px" : '0',
          }}
        >
          {t("qr.preview")}
        </h4>
      )}
    </div>
  )
}
export default QrDesignTextEditor
