import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../../api/api"
import { store } from "../../store"
import { IContactsInitState, IFilter } from "../../../components/Contacts/types"

const initialState: IContactsInitState = {
  data: [],
  contacts_uploaded: [],
  data_on_event: [],
  data_uploaded: [],
  new_data: [],
  new_contacts: [],
  current_page: 1,
  limit: 30,
  total_pages: 0,
  progress_id: 0,
  isLoading: false,
  error: "",
}

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async (params: IFilter, { rejectWithValue }) => {
    try {
      const res = await api.contacts.getContacts(
        store.getState().contacts.current_page,
        store.getState().contacts.limit,
        params,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getContactsMobile = createAsyncThunk(
  "contacts/getContactsMobile",
  async (params: IFilter, { rejectWithValue }) => {
    try {
      const res = await api.contacts.getContacts(
        store.getState().contacts.current_page,
        store.getState().contacts.limit,
        params,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getContactsOnEvent = createAsyncThunk(
  "contacts/getContactsOnEvent",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.getContactsOnEvent(
        store.getState().contacts.current_page,
        store.getState().contacts.limit,
        params,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getContactsOnEventMobile = createAsyncThunk(
  "contacts/getContactsOnEventMobile",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.getContactsOnEvent(
        store.getState().contacts.current_page,
        store.getState().contacts.limit,
        params,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getContactsForMessages = createAsyncThunk(
  "contacts/getContactsforMessages",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.getContactsForMessages(
        params.id,
        params.current_page,
        params.limit,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const deleteContacts = createAsyncThunk(
  "contacts/deleteContacts",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.deleteContacts(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const changeContact = createAsyncThunk(
  "contacts/changeContact",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.changeContact(params)
      return res.data
    } catch (error: any) {
      let { errors } = error.response.data
      return rejectWithValue(errors)
    }
  },
)

export const addContact = createAsyncThunk(
  "contacts/addContact",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.addContact(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response.data)
    }
  },
)

export const importFile = createAsyncThunk(
  "contacts/importFile",
  async (params: FormData, { rejectWithValue }) => {
    try {
      const res = await api.contacts.importContacts(params)
      return res.data.progress_id
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response.data)
    }
  },
)

export const importGuests = createAsyncThunk(
  "contacts/importGuests",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.importGuests(params)
      return res.data.progress_id
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(error.response.data)
    }
  },
)

export const deleteGuest = createAsyncThunk(
  "contacts/deleteGuest",
  async (ids: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.deleteGuest(ids)
      return res.data
    } catch (error: any) {
      console.log(error)
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const addGuest = createAsyncThunk(
  "contacts/addGuest",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.addGuest(params)
      console.log(res)
      return res.data
    } catch (error: any) {
      let { errors } = error.response.data
      return rejectWithValue(errors)
    }
  },
)

export const updateGuest = createAsyncThunk(
  "contacts/updateGuest",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.updateGuest(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const importFromContacts = createAsyncThunk(
  "contacts/importFromContacts",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.contacts.importFromContacts(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

// helper

const handleloading = (state: IContactsInitState) => {
  state.isLoading = true
}

const handleError = (
  state: IContactsInitState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false
  state.error = action.payload
}

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setLoading: handleloading,
    setError: handleError,
    setLimit: (state: IContactsInitState, action: PayloadAction<number>) => {
      state.limit = action.payload
    },
    setCurrentPage: (
      state: IContactsInitState,
      action: PayloadAction<number>,
    ) => {
      state.current_page = action.payload
    },
    clearDataOnEvent: (state: IContactsInitState) => {
      state.data_on_event = []
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.contacts_uploaded = action.payload.data
      state.total_pages = action.payload.meta.pagination.total_pages
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getContactsMobile.fulfilled, (state, action) => {
      state.contacts_uploaded =
      action.payload.data.length > 0
          ? state.contacts_uploaded.filter(
              (item) => item.id === action.payload.data[0].id,
            ).length > 0
            ? state.contacts_uploaded
            : [...state.contacts_uploaded, ...action.payload.data]
          : state.contacts_uploaded
      state.total_pages = action.payload.meta.pagination.total_pages
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getContactsOnEventMobile.fulfilled, (state, action) => {
      state.isLoading = true
      state.data_uploaded =
        action.payload.data.length > 0
          ? state.data_uploaded.filter(
              (item) => item.id === action.payload.data[0].id,
            ).length > 0
            ? state.data_uploaded
            : [...state.data_uploaded, ...action.payload.data]
          : state.data_uploaded
      state.total_pages = action.payload.meta.pagination.total_pages
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getContactsOnEvent.fulfilled, (state, action) => {
      state.isLoading = true
      state.data_on_event = action.payload.data
      state.data_uploaded = action.payload.data
      state.total_pages = action.payload.meta.pagination.total_pages
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getContactsForMessages.pending, (state, _action) => {
      state.isLoading = true
      state.error = ""
    })

    builder.addCase(getContactsForMessages.fulfilled, (state, action) => {
      state.isLoading = true
      state.data_on_event = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteContacts.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(deleteGuest.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(addGuest.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(updateGuest.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(importFromContacts.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(addContact.fulfilled, (state, _action) => {
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(importFile.fulfilled, (state, action) => {
      state.progress_id = action.payload
    })

    builder.addCase(importGuests.fulfilled, (state, action) => {
      state.progress_id = action.payload
    })

    builder.addCase(changeContact.rejected, (state, action: any) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export const {
  setLoading,
  setError,
  setLimit,
  setCurrentPage,
  clearDataOnEvent } = contactsSlice.actions

export default contactsSlice.reducer
