import React from "react"
import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IconFilter } from "../utils/Icons/CustomIcons"
import { useEffect, useState } from "react"

const GuestsFilter = ({
  filter,
  setFilter,
  setCheckedFilter,
  checkedFilter,
  setCheckedReminder,
  checkedReminder,
}: any) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<any>("0,1,2,5,6")
  const [number, setNumber] = useState<any>("")
  const onCheck = (name: any) => {
    setNumber("")
    setCheckedReminder("")
    if (checkedFilter === name) {
      setCheckedFilter("")
      setStatus("0,1,2,5,6")
      setFilter({ ...filter, statusNumber: status })
    } else {
      setCheckedFilter(name)
      switch (name) {
        case "ignore":
          setStatus("0")
          break
        case "decline":
          setStatus("2")
          break
          case "visited":
          setStatus("5")
          break
        case "failed":
          setStatus("6")
          break
        case "going":
          setStatus("1")
          break
      }
      setFilter({ ...filter, statusNumber: status })
    }
  }

  const onCheckReminder = (name: any) => {
    setStatus("0,1,2,5,6")
    setCheckedFilter("")
    if (checkedReminder === name) {
      setCheckedReminder("")
      setNumber("")
      setFilter({ ...filter, reminderNumber: number })
    } else {
      setCheckedReminder(name)
      switch (name) {
        case "No":
          setNumber(0)
          break
        case "Yes":
          setNumber(1)
          break
      }
      setFilter({ ...filter, reminderNumber: number })
    }
  }

  useEffect(() => {
    setFilter({ ...filter, statusNumber: status, reminderNumber: number })
  }, [number, status])

  return (
    <Dropdown
      className={
        "dropdown-block dropdown--not-arrow dropdown-filter reminders-filter"
      }
    >
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent reminders-page__btn">
        <IconFilter />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <span className="dropdown-filter__title">
          {t("reminders.filter.status.title")}
        </span>
        <div className="check-list">
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-ignore"
              checked={checkedFilter === "ignore"}
              onChange={() => onCheck("ignore")}
            />
            <label htmlFor="filter-ignore">
              {t("reminders.filter.status.ignore")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-going"
              checked={checkedFilter === "going"}
              onChange={() => onCheck("going")}
            />
            <label htmlFor="filter-going">
              {t("reminders.filter.status.going")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-decline"
              checked={checkedFilter === "decline"}
              onChange={() => onCheck("decline")}
            />
            <label htmlFor="filter-decline">
              {t("reminders.filter.status.decline")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-failed"
              checked={checkedFilter === "failed"}
              onChange={() => onCheck("failed")}
            />
            <label htmlFor="filter-failed">
              {t("reminders.filter.status.failed")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-visited"
              checked={checkedFilter === "visited"}
              onChange={() => onCheck("visited")}
            />
            <label htmlFor="filter-visited">
              {t("reminders.filter.status.visited")}
            </label>
          </div>
        </div>
        <span className="dropdown-filter__title">
          {t("reminders.filter.freeReminder.title")}
        </span>
        <div className="check-list">
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-yes"
              checked={checkedReminder === "Yes"}
              onChange={() => onCheckReminder("Yes")}
            />
            <label htmlFor="filter-yes">
              {t("reminders.filter.freeReminder.yes")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-no"
              checked={checkedReminder === "No"}
              onChange={() => onCheckReminder("No")}
            />
            <label htmlFor="filter-no">
              {t("reminders.filter.freeReminder.no")}
            </label>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestsFilter
