export const guests = [
  {
    value: "Mrs Sama Al-Rayez",
    label: "Mrs Sama Al-Rayez",
    type: "suffix_full_name",
  },
  { value: "Mrs Sama", label: "Mrs Sama", type: "suffix_name" },
  { value: "Mrs Al-Rayez", label: "Mrs Al-Rayez", type: "suffix_surname" },
  {
    value: "Sama Al-Rayez",
    label: "Sama Al-Rayez",
    type: "full_name",
  },
  { value: "Sama", label: "Sama", type: "first_name" },
  { value: "Al-Rayez", label: "Al-Rayez", type: "surname" },
]

export const alignOptions = [
  {
    value: "left",
    label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
  },
  {
    value: "center",
    label: <img src="/vector/QrDesign/alignt_format_center.svg" />,
  },
  {
    value: "right",
    label: <img src="/vector/QrDesign/alignt_format_right.svg" />,
  },
]

export const fontOptions = [
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Inter",
    value: "Inter",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "SF Pro Display",
    value: "SF Pro Display",
  },
  {
    label: "Barlow",
    value: "Barlow",
  },
]
