import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useAppSelector } from "../../redux/store"
import { IconArrowMessage } from "../utils/Icons/CustomIcons"
import Preloader from "../utils/Preloader/Preloader"
import HoverTooltip from "./HoverTooltip"

type Props = {
  right: boolean
  setRight: any
  onSubmit: any
  onKeyDown: any
  value: string
  setValue: any
  dialogRef: any
  contactId: any
  name: string
  eventId: number
  canSend: boolean
}

type Message = {
  created_at: string
  id: number
  is_incoming: number
  message: string
  read_status: number
  send_from_whatsapp: boolean
  whatsapp_message_can_be_sent: boolean
}

const Dialog = ({
  right,
  setRight,
  onSubmit,
  onKeyDown,
  value,
  setValue,
  dialogRef,
  name,
  contactId,
  eventId,
  canSend,
}: Props) => {
  const { t } = useTranslation()
  const conversations = useAppSelector((state) => state.messages.currentData)
  const isLoading = useAppSelector((state) => state.messages.isLoading)

  const getDate = (date: string, index: number) => {
    const currentDate = dayjs(new Date(date)).format("DD MMM")
    const fullCurrentDate = dayjs(date)
      .format("DD MMM")
      .includes(dayjs().format("DD MMM"))
      ? `Today, ${currentDate}`
      : currentDate
    const prevDate =
      index !== 0
        ? dayjs(conversations[index - 1].created_at).format("DD MMM")
        : 0
    return currentDate === prevDate ? "" : fullCurrentDate
  }

  return (
    <>
      {isLoading && <Preloader />}
      <div
        className={`messages-dialog ${!right ? "messages-return__none" : ""}`}
      >
        <div className="messages-dialog__header">
          <img
            src={"/vector/avatar3.svg"}
            alt="Avatar"
            className="messages-dialog__avatar"
          />
          <span className="messages-dialog__name">{name}</span>
        </div>
        {conversations.length !== 0 ? (
          <div className="messages-body__mobile">
            <div className="messages-body" ref={dialogRef}>
              {conversations
                // sort((a, b) => a.created_at > b.created_at ? 1 : -1)
                .map((message: Message, index: number) => {
                  return (
                    <div key={index}>
                      <span
                        className="messages-date"
                        style={{
                          display: `${
                            getDate(message.created_at, index) === ""
                              ? "none"
                              : "flex"
                          }`,
                        }}
                      >
                        {getDate(message.created_at, index)}
                      </span>
                      <div
                        className={`messages-message ${
                          message.is_incoming === 0
                            ? "messages-message__own"
                            : ""
                        }
          `}
                      >
                        <span className="messages-message__name">
                          {message.is_incoming === 0
                            ? `${t("messages.you")}`
                            : `${name}`}
                        </span>
                        <span className="messages-message__text">
                          {message.message}
                        </span>
                        <span className="messages-message__time">
                          {Math.floor(
                            (Date.parse(message.created_at) / 1000 / 60 / 60) %
                              24,
                          )}
                          :
                          {Math.floor(
                            (Date.parse(message.created_at) / 1000 / 60) % 60,
                          )}
                        </span>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        ) : (
          <div className="messages-body__empty">
            <span className="messages-body__text">
              {t("messages.noMessages")}
            </span>
            <span className="messages-body__text">{t("messages.start")}</span>
          </div>
        )}
        {!canSend ? (
          <HoverTooltip text={t("messages.tooltip")} id={"t-15"}>
            <div className="messages-send">
              <input
                type="text"
                value={value}
                disabled={!canSend ? true : false}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => onKeyDown(e)}
                className="messages-send__input"
              />
              <span className="messages-send__btn" onClick={onSubmit}>
                <IconArrowMessage />
              </span>
            </div>
          </HoverTooltip>
        ) : (
          <div className="messages-send">
            <input
              type="text"
              value={value}
              disabled={!canSend ? true : false}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => onKeyDown(e)}
              className="messages-send__input"
            />
            <span className="messages-send__btn" onClick={onSubmit}>
              <IconArrowMessage />
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default Dialog
