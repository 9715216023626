import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import styles from "./ActionModal.module.scss"

const ActionModal = ({ show, setShow, id }: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShow(!show)
    navigate(`/${id}`)
  }

  const handleStayHere = () => {
    setShow(!show)
  }

  return (
    <Modal
      className={`${styles.addPopup} select-guest__add-modal`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("selectContacts.title")}
          </h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={handleStayHere}>
              {t("selectContacts.stayHere")}
            </span>
            <button onClick={handleCloseModal} className="btn btn-dark">
              {t("selectContacts.goToEvent")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ActionModal
