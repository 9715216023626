import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { IconClose, IconConfirm } from "../../utils/Icons/CustomIcons"
import "./ThankModal.scss"

const ThankModal = ({ show, setShow }: { show: boolean; setShow: any }) => {
  const { t } = useTranslation()

  return (
    <Modal className={`thank-modal`} show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div className="thank-container">
          <div className="thank">
            <div className="thank-inner">
              <div className="thank-main__header">
                <IconConfirm />
                <span className="thank-main__header-title">
                  {t("register.thank.title")}
                </span>
                <div
                  className="d-flex align-items-center"
                  onClick={() => setShow(false)}
                >
                  <IconClose className="thank-main__header-close" />
                </div>
              </div>
              <section className="thank-main">
                <div className="thank-main__form">
                  <div className="thank-main__card">
                    <p className="thank-main__card-description">
                      {t("register.thank.description")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn btn-dark"
                      onClick={() => setShow(false)}
                    >
                      {t("buttons.ok")}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ThankModal
