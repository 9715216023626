import { useTranslation } from "react-i18next"
import {
  IconBtnCreate,
  IconDeleteQuest,
  IconImport,
  IconSearch,
  IconSorting,
} from "../../components/utils/Icons/CustomIcons"
import { useEffect, useState } from "react"
import useDebounce from "../../hooks/useDebounce"
import { Dropdown } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { getContacts, setLimit } from "@/redux/slices/contacts/contactsSlices"

const ContactsHead = ({
  filter,
  setFilter,
  setAddShow,
  setImportShow,
  checked,
  setChecked,
  setAllQuestsChecked,
  deleteChecked,
  countContacts,
  setCountContacts,
}: any) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { contacts_uploaded, data } = useAppSelector((state) => state.contacts)
  const [search, setSearch] = useState("")

  const debouncedSearchTerm = useDebounce(search, 500)

  const changeFilter = (sort: string, order: string) => {
    setFilter({ ...filter, sort: sort, order: order })
  }

  const onDelete = () => {
    deleteChecked(checked)
    setChecked([])
    setAllQuestsChecked([])
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setFilter({ ...filter, searchValue: search })
    }

    if (search === "") {
      setFilter({ ...filter, searchValue: search })
    }
  }, [debouncedSearchTerm])

  return (
    <div className="contacts-head">
      <h2 className="caption">{t("contacts.title")}</h2>

      <div className="input-search">
        <input
          type="text"
          placeholder={t("placeholder.search")}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />

        <button className="input-search__btn">
          <IconSearch />
        </button>
      </div>

      <div className="contacts-head__controls">
        <a
          href="#"
          className="btn btn-simple"
          onClick={() => {
            setImportShow(true)
          }}
        >
          <IconImport />
          {t("buttons.importGuests")}
        </a>
        <a
          href="#"
          className="btn btn-dark"
          onClick={() => {
            setAddShow(true)
          }}
        >
          <IconBtnCreate />
          {t("buttons.addGuests")}
        </a>
      </div>
      <div className="contacts-body__controls mobile">
        <Dropdown className="dropdown-block sorting-mobile">
          {data.length > 0 && (
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              <span className="sorting-mobile__text">
                {t("contacts.sorting.title")} <IconSorting />
              </span>
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=first_name",
                )
              }
            >
              {t("contacts.sorting.name")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=email",
                )
              }
            >
              {t("contacts.sorting.email")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=phone",
                )
              }
            >
              {t("contacts.sorting.phone")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=created_at",
                )
              }
            >
              {t("contacts.sorting.date")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {checked.length !== 0 && (
          <span
            className="delete-selected btn btn-simple"
            onClick={() => {
              onDelete()
            }}
          >
            <IconDeleteQuest />
            {t("buttons.deleteSelected")}
          </span>
        )}

        {contacts_uploaded.length > 0 && (
          <Dropdown className="dropdown-block show-count">
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              {t("contacts.showTitle")} {countContacts}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setCountContacts(30)
                  dispatch(setLimit(30))
                  dispatch(getContacts(filter))
                }}
              >
                {t("contacts.sorting.title")} 30
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCountContacts(60)
                  dispatch(setLimit(60))
                  dispatch(getContacts(filter))
                }}
              >
                {t("contacts.sorting.title")} 60
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  )
}

export default ContactsHead
