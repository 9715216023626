import { useState, useEffect, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getPreview,
  clearImg,
  createEvent,
  eventDesign,
  clearEventId,
  clearQrId,
  clearCurrentEvent,
} from "../../redux/slices/events/eventsSlice"
import { useNavigate } from "react-router-dom"
import CreateEventForm from "./CreateEventForm"
import CreateEventPreview from "./CreateEventPreview"
import CreateEventPreviewOpen from "./CreateEventPreviewOpen"
import { useForm } from "react-hook-form"

interface IForm {
  name: string
  address: string
  type: string
  file: string
  start_datetime: string
  language: string
}

const notify = (text: string, color: boolean) => {
  color
    ? toast.error(text, {
        position: "top-right",
        autoClose: 3000,
      })
    : toast.success(text, {
        position: "top-right",
        autoClose: 3000,
      })
}

const CreateEventPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { lang, error } = useAppSelector((state) => state.auth)
  const { new_event_img, preview } = useAppSelector((state) => state.events)

  const [language, setLanguage] = useState(lang)
  const [showOpen, setShowOpen] = useState(false)
  const [address, setAddress] = useState({ venue: "", address: "" })

  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<IForm>({})

  const defaultEvent = {
    name: "",
    address: {
      address: "",
      lat: 0,
      lng: 0,
    },
    type: -1,
    event_created_date: "",
    language: language,
    img: {
      path: new_event_img?.file_path,
      id: new_event_img?.id,
    },
    invitation_show_qr_code: true,
    qr_wallet: false,
    is_open_event: false,
    start_datetime: "",
    venue: "",
  }

  const [event, setEvent] = useState<any>(defaultEvent)

  const onCancelHandle = () => {
    setEvent(defaultEvent)
    navigate("/events")
  }

  useEffect(() => {
    dispatch(getPreview())

    return () => {
      dispatch(clearImg())
      setEvent(defaultEvent)
      dispatch(clearQrId())
    }
  }, [])

  useEffect(() => {
    setEvent({
      ...event,
      img: {
        path: new_event_img.file_path,
        id: new_event_img.id,
      },
    })
  }, [new_event_img])

  const onSubmit = (e: SyntheticEvent, manage: string) => {
    e.preventDefault()
    clearErrors()
    new_event_img.id === 0
      ? setError("file", { type: "custom", message: t("errors.fieldRequired") })
      : dispatch(
          createEvent({
            ...event,
            address: {
              ...event.address,
              address: address.address ? address.address : address.venue,
            },
            venue: address.address ? address.venue : "",
          }),
        )
          .then((data: any) => {
            if (data.payload.errors) {
              event.address.address === "" &&
                setError("address", {
                  type: "custom",
                  message: t("errors.fieldRequired"),
                })
              Object.entries(data.payload.errors).forEach(
                ([field, messages]: any) => {
                  messages.forEach((message: any) => {
                    setError(field, { type: "custom", message })
                  })
                },
              )
              notify(
                (Object.values(data.payload.errors) as string[])[0][0],
                true,
              )
            } else {
              let eventD = {
                event_id: data.payload.data.event_id,
                pattern_id: new_event_img.id,
              }
              new_event_img.id === 0
                ? notify(t("notify.upload"), true)
                : dispatch(eventDesign(eventD)).then((res: any) => {
                    if (res.payload.data) {
                      if (manage === "") {
                        dispatch(clearEventId())
                        notify(t("notify.created"), false)
                        navigate("/events")
                        dispatch(clearQrId())
                      } else if (manage === "qr") {
                        dispatch(clearQrId())
                        dispatch(clearCurrentEvent())
                        data.payload.data.event_id && navigate(`/qr-design/${data.payload.data.event_id}`)
                      } else {
                        setShowOpen(true)
                      }
                    }
                  })
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  return (
    <div className="createEventPage">
      <div className="container">
        <h2 className="caption-two">{t("event.titleCreate")}</h2>
        <div className="createEventForm bl-default">
          <CreateEventForm
            register={register}
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            address={address}
            setAddress={setAddress}
            event={event}
            setEvent={setEvent}
            new_event_img={new_event_img}
            error={error}
            onCancelHandle={onCancelHandle}
            language={language}
            setLanguage={setLanguage}
            setShowOpen={setShowOpen}
            showOpen={showOpen}
          />
          {event.is_open_event ? (
            <CreateEventPreviewOpen
              event={event}
              new_event_img={new_event_img}
              loadPreview={preview}
              language={language}
              onSubmit={onSubmit}
            />
          ) : (
            <CreateEventPreview
              event={event}
              new_event_img={new_event_img}
              loadPreview={preview}
              language={language}
              onSubmit={onSubmit}
            />
          )}
          <div className="createEventForm__controls">
            <span className="btn btn-simple" onClick={onCancelHandle}>
              {t("buttons.cancel")}
            </span>
            <button
              type="submit"
              onClick={(e) => onSubmit(e, "")}
              className="btn btn-dark"
            >
              {t("buttons.save")}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
    </div>
  )
}

export default CreateEventPage
