import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "@/redux/store"
import { deleteEventQuestions } from "@/redux/slices/events/eventsSlice"
import styles from "./DeleteQuestionModal.module.scss"

type DeleteQuestionModal = {
  show: boolean
  setShow: (arg0: boolean) => void
  setValues: any
  values: any
  id: number
  setShowOpen: (arg0: boolean) => void
}

const DeleteQuestionModal = ({
  show,
  setShow,
  setValues,
  values,
  id,
  setShowOpen,
}: DeleteQuestionModal) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleDeleteQuestion = () => {
    if (values.find((value: any) => value.id === id).order !== 0) {
      dispatch(deleteEventQuestions(id))
    }
    setValues(values.filter((value: any) => value.id !== id))
    setShow(false)
    setShowOpen(true)
  }

  return (
    <Modal
      className={`${styles.addPopup} ${"delete-question-modal"}`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("openModal.question")}
          </h2>

          <div className={styles.controls}>
            <span
              className="btn btn-simple"
              onClick={() => {
                setShow(!show)
                setShowOpen(true)
              }}
            >
              {t("buttons.no")}
            </span>
            <button onClick={handleDeleteQuestion} className="btn btn-dark">
              {t("buttons.yes")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteQuestionModal
