import { useAppSelector } from "@/redux/store"
import "./InvitationPhone.scss"
import {
  IconArrowDownTriangle,
  IconBattery,
  IconDatePicker,
  IconFooterIcons,
  IconLocation,
  IconLock,
  IconRefresh,
  IconSignal,
  IconTextSize,
  IconTime,
  IconTitle,
  IconWifi,
} from "../../utils/Icons/CustomIcons"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const InvitationPhone = ({ event }: any) => {
  const { t } = useTranslation()
  const { new_event_img, currentEvent } = useAppSelector(
    (state) => state.events,
  )

  return (
    <div className="invitation-phone">
      <div className="phone">
        <div className="phone-inner">
          <section className="phone-header">
            <IconTime />
            <div className="phone-header__icons">
              <IconSignal />
              <IconWifi />
              <IconBattery />
            </div>
          </section>

          <section className="phone-title">
            <IconTitle />
            <div className="phone-title__lang">
              <span> {t("event.preview.invitationPage.lang")}</span>
              <IconArrowDownTriangle />
            </div>
          </section>

          <section className="phone-main">
            <div className="picture">
              <img
                src={
                  new_event_img.file_path
                    ? new_event_img.file_path
                    : currentEvent
                    ? currentEvent.pattern
                    : "/vector/no-foto.svg"
                }
                alt=""
              />
            </div>
            <div className="phone-main__bottom">
              <div className="phone-main__content">
                <p className="phone-main__description">
                  {t("event.preview.invitationPage.desc")}
                </p>
                <p className="phone-main__title">{event.name}</p>
              </div>
              <div className="d-flex gap-3 phone-main__card">
                <IconDatePicker />
                <div className="phone-main__card-description">
                  <p className="phone-main__card-caption">
                    {t("event.preview.invitationPage.date")}
                  </p>
                  <p className="phone-main__card-place">
                    {event.start_datetim
                      ? dayjs(event.start_datetime).format("dddd, DD MMM YYYY")
                      : ""}
                  </p>
                  <p className="phone-main__card-address">
                    {event.start_datetime
                      ? dayjs(event.start_datetime).format("h:mm")
                      : ""}{" "}
                    {event.start_datetime
                      ? dayjs(event.start_datetime)
                          .format("a")
                          .toLowerCase()
                          .replace(/m$/, ".m.")
                      : ""}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3 phone-main__card">
                <IconLocation />
                <div className="phone-main__card-description">
                  <p className="phone-main__card-caption">
                    {t("event.preview.invitationPage.location")}
                  </p>
                  <p className="phone-main__card-place">{event.venue}</p>
                  <p className="phone-main__card-address">
                    {event.venue !== ""
                      ? event.address.address
                          .substring(1 + event.venue.length)
                          .trim()
                      : event.address.address}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-3 phone-main__buttons">
                <button className="btn btn-simple">
                  {t("event.preview.invitationPage.decline")}
                </button>
                <button className="btn btn-dark">
                  {t("event.preview.invitationPage.accept")}
                </button>
              </div>
            </div>
          </section>

          <section className="phone-footer">
            <div className="phone-footer__http">
              <IconTextSize />
              <IconLock />
              <span className="phone-footer__http-text">
                app.mazoom.sa/register/819
              </span>
              <IconRefresh />
            </div>
            <IconFooterIcons />
            <div className="phone-footer__http-bottom">
              <div className="phone-footer__http-line" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default InvitationPhone
