import { useTranslation } from "react-i18next"
import {
  IconMail,
  IconWhatsapp,
} from "../../components/utils/Icons/CustomIcons"
import "react-toastify/dist/ReactToastify.css"
import { useAppSelector } from "../../redux/store"

const StatusNo = () => {
  const { error_text } = useAppSelector((state) => state.events)
  const { t } = useTranslation()

  return (
    <>
      <div className="test-row">
        <div className="test-col bl-default test-error">
          <div className="test-error__container">
            <div className="test-error__error error-section">
              <span className="test-error__text-error">{t("test.error")}</span>
              <span className="test-error__text-error">{error_text}</span>
            </div>
            <div className="test-error__error error-section">
              <span className="test-error__text-description">
                {t("test.support")}
              </span>
            </div>
            <div className="test-error__error">
              <IconWhatsapp />
              <a className="test-error__text-contact" target="_blank" href="https://web.whatsapp.com/send?phone=+966 505278757">+966 505278757</a>
            </div>
            <div className="test-error__error">
              <IconMail />
              <a className="test-error__text-contact" href="mailto:admin@mazoom.sa">admin@mazoom.sa</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusNo
