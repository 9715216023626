import { OverlayTrigger, Tooltip } from "react-bootstrap"

const HoverTooltip = ({ children, text, id }: any) => {
  return (
    <OverlayTrigger overlay={<Tooltip id={id}>{text}</Tooltip>}>
      {children}
    </OverlayTrigger>
  )
}

export default HoverTooltip
