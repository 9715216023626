import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { store } from "../../store"
import api from "../../../api/api"
import { IMessagesInitState } from "../../../components/Events/types"

const initialState: IMessagesInitState = {
  data: [],
  total: 10,
  currentData: [],
  isLoading: false,
  result: {},
  contactId: 0,
  message: "",
  error: "",
  page: 1,
}

type Message = {
  event_contact_id: number
  sent_from_whatsapp: number
  message: string
  is_incoming: number
  updated_at: string
  created_at: string
  id: number
  guest_name: any
}

export const getMessages = createAsyncThunk(
  "messages/getMessages",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.messages.getMessages()
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getMessagesWithParams = createAsyncThunk(
  "messages/getMessagesWithParams",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.messages.getMessagesWithParams(
        store.getState().messages.page,
        store.getState().messages.total,
      )
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const getConversation = createAsyncThunk(
  "messages/getConversation",
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await api.messages.getConversation(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const markAsRead = createAsyncThunk(
  "messages/markAsRead",
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await api.messages.markAsRead(id)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

export const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async (
    params: { id: number; contactId: number; message: string },
    { rejectWithValue },
  ) => {
    try {
      const res = await api.messages.sendMessage(params)
      return res.data
    } catch (error: any) {
      let { message } = error.response.data
      return rejectWithValue(message)
    }
  },
)

// helper

const handleloading = (state: IMessagesInitState) => {
  state.isLoading = true
}

const handleError = (
  state: IMessagesInitState,
  action: PayloadAction<string>,
) => {
  state.isLoading = false
  state.error = action.payload
}

// slice

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setLoading: handleloading,
    setError: handleError,
    setPage: (state: IMessagesInitState, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    clearMessages: (state: IMessagesInitState) => {
      state.data = []
    },
    setMessages: (
      state: IMessagesInitState,
      action: PayloadAction<Message>,
    ) => {
      state.currentData = [
        ...state.currentData,
        {
          id: action.payload.id,
          send_from_whatsapp: action.payload.sent_from_whatsapp,
          message: action.payload.message,
          read_status: 1,
          created_at: action.payload.created_at,
          is_incoming: action.payload.is_incoming,
        },
      ]
    },
    setNewUser: (state: IMessagesInitState, action: PayloadAction<any>) => {
      state.data = state.data.map((item: any) => {
        if (item.id === action.payload.eventId) {
          item.conversations.data = item.conversations.data.find(
            (item: any) => item.contact.data.id === action.payload.contactId,
          )
            ? [...item.conversations.data]
            : [
                ...item.conversations.data,
                {
                  id:
                    item.conversations.data.length > 0
                      ? item.conversations.data[
                          item.conversations.data.length - 1
                        ].id + 100
                      : 100,
                  send_from_whatsapp: false,
                  message: "",
                  read_status: 1,
                  created_at: dayjs(new Date()).toISOString(),
                  not_read_received_messages_count: 0,
                  whatsapp_message_can_be_sent: true,
                  contact: {
                    data: {
                      id: action.payload.contactId,
                      first_name: action.payload.first_name,
                      last_name: action.payload.last_name,
                      suffix: action.payload.suffix,
                      email: action.payload.email,
                      phone: action.payload.phone,
                    },
                  },
                },
              ]
        }
        return item
      })
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.data = [...action.payload.data]
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getMessagesWithParams.fulfilled, (state, action) => {
      let arr2 = action.payload.data
      let arr1 = state.data
      arr2.forEach((item: any) => {
        const exists = arr1.find(
          (existingItem: any) => existingItem.id === item.id,
        )
        if (!exists) {
          return arr1.push(item)
        }
      })
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(getConversation.fulfilled, (state, action) => {
      state.currentData = action.payload.data
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.contactId = action.meta.arg.contactId
      state.message = action.meta.arg.message
      state.result = action.meta.arg
      // state.currentData = [
      //   ...state.currentData,
      //   {
      //     id: state.currentData[state.currentData.length - 1].id + 1,
      //     send_from_whatsapp: false,
      //     message: action.meta.arg.message,
      //     read_status: 1,
      //     created_at: dayjs(new Date()).toISOString(),
      //     is_incoming: 0,
      //   },
      // ]
      // state.data = state.data.map((item: any) => {
      //   if (
      //     item.conversations.data.some(
      //       (conv: any) => conv.contact.data.id === action.meta.arg.contactId,
      //     )
      //   ) {
      //     item.conversations.data = item.conversations.data.map((conv: any) => {
      //       if (conv.contact.data.id === action.meta.arg.contactId) {
      //         ;(conv.message = action.meta.arg.message),
      //           (conv.not_read_received_messages_count = 0),
      //           (conv.created_at = dayjs(new Date()).toISOString())
      //       }
      //       return conv
      //     })
      //   }
      //   return item
      // })
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(markAsRead.fulfilled, (state, action) => {
      // state.data = state.data.map((item: any) => {
      //   if (
      //     item.conversations.data.some(
      //       (conv: any) => conv.contact.data.id === action.meta.arg,
      //     )
      //   ) {
      //     item.conversations.data = item.conversations.data.map((conv: any) => {
      //       if (conv.contact.data.id === action.meta.arg) {
      //         conv.not_read_received_messages_count = 0
      //       }
      //       return conv
      //     })
      //   }
      //   return item
      // })
      state.isLoading = false
      state.error = ""
    })
  },
})

export const {
  setLoading,
  setError,
  setMessages,
  setNewUser,
  setPage,
  clearMessages,
} = messagesSlice.actions

export default messagesSlice.reducer
