import { useTranslation } from "react-i18next"
import {
  IconConfirm,
  IconFailed,
  IconRecieved,
  IconReject,
  IconScanned,
  IconSending,
  IconSendingBg,
  IconSent,
  IconWaiting,
} from "../../components/utils/Icons/CustomIcons"
import { useAppSelector } from "../../redux/store"

const StatusOk = () => {
  const { t } = useTranslation()
  const { message_status, invitation_status, phone } = useAppSelector(
    (state) => state.events,
  )

  const invitationStatuses = [
    { text: t("testModal.statuses.confirmed"), image: <IconConfirm />, id: 1 },
    { text: t("testModal.statuses.rejected"), image: <IconReject />, id: 2 },
    { text: t("testModal.statuses.waiting"), image: <IconWaiting />, id: 0 },
    { text: t("testModal.statuses.scanned"), image: <IconScanned />, id: 4 },
    { text: t("testModal.statuses.failed"), image: <IconFailed />, id: 6 },
    { text: t("testModal.statuses.sending"), image: <IconSendingBg />, id: 7 },
  ]
  
  const messageStatuses = [
    { text: t("testModal.statuses.sent"), image: <IconSent />, id: 1 },
    { text: t("testModal.statuses.delivered"), image: <IconSent />, id: 2 },
    { text: t("testModal.statuses.recieved"), image: <IconRecieved color="#B3B2AD" />, id: 3 },
    { text: t("testModal.statuses.read"), image: <IconRecieved color="#44BAF2" />, id: 4 },
    { text: t("testModal.statuses.failed"), image: <IconFailed />, id: 5 },
    { text: t("testModal.statuses.sending"), image: <IconSending />, id: 0 },
  ]

  return (
    <>
      <div className="description-page">
        <span className="test-description">
          {t("test.sent")} +{phone}
        </span>
      </div>

      <div className="test-row test-status-row">
        <div className="test-col test-col--balance bl-default">
          <span className="test-status">{t("test.invitationStatus")}</span>
          <div className="test-right">
            <div className="test-right__status">
              <span className="test-right__text">
                {invitationStatuses.find(
                  (item) => item.id === invitation_status,
                )
                  ? invitationStatuses.find(
                      (item) => item.id === invitation_status,
                    )?.text
                  : ""}
              </span>
              {invitationStatuses.find((item) => item.id === invitation_status)
                ? invitationStatuses.find(
                    (item) => item.id === invitation_status,
                  )?.image
                : ""}
            </div>
          </div>
        </div>

        <div className="test-col test-col--balance bl-default">
          <span className="test-status">{t("test.messageStatus")}</span>
          <div className="test-right">
            <div className="test-right__status">
              <span className="test-right__text">
                {messageStatuses.find((item) => item.id === message_status)
                  ? messageStatuses.find((item) => item.id === message_status)
                      ?.text
                  : ""}
              </span>
              {messageStatuses.find((item) => item.id === message_status)
                ? messageStatuses.find((item) => item.id === message_status)
                    ?.image
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusOk
