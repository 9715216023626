import { useState } from "react"
import { useTranslation } from "react-i18next"
import "react-datepicker/dist/react-datepicker.css"
import {
  IconArrowDown,
  IconArrowUp,
  IconClose,
} from "../../utils/Icons/CustomIcons"

const CustomTimeInput = ({ date, value, onChange, setFocus }: any) => {
  const { t } = useTranslation()
  const [time, setTime] = useState({
    hours: value === "" ? 0 : value.substring(0, 2),
    minutes: value === "" ? 0 : value.substring(3, 5),
  })

  const handleHoursChange = (value: any) => {
    onChange(String(value) + ":" + String(time.minutes))
  }

  const handleMinutesChange = (value: any) => {
    onChange(String(time.hours) + ":" + String(value))
  }

  return (
    <div className="timeEvent">
      <span className="title">{t("event.titleTime")}</span>
      <div className="timeEvent__inputs">
        <div className="timeEvent__controls">
          <div className="arrows">
            <span
              className="arrows__up"
              onClick={() => {
                if (time.hours >= 23) {
                  setTime({ ...time, hours: 0 })
                  handleHoursChange(0)
                } else {
                  setTime({ ...time, hours: Number(time.hours) + 1 })
                  handleHoursChange(Number(time.hours) + 1)
                }
              }}
            >
              <IconArrowUp />
            </span>

            <span
              className="arrows__down"
              onClick={() => {
                if (time.hours <= 0) {
                  setTime({ ...time, hours: 23 })
                  handleHoursChange(23)
                } else {
                  setTime({ ...time, hours: Number(time.hours) - 1 })
                  handleHoursChange(Number(time.hours) - 1)
                }
              }}
            >
              <IconArrowDown />
            </span>
          </div>
          <input type="number" value={time.hours} />
        </div>
        <span className="toh">:</span>
        <div className="timeEvent__controls">
          <input type="number" value={time.minutes} />
          <div className="arrows">
            <span
              className="arrows__up"
              onClick={() => {
                if (time.minutes >= 50) {
                  setTime({ ...time, minutes: 0 })
                  handleMinutesChange(0)
                } else {
                  setTime({ ...time, minutes: Number(time.minutes) + 10 })
                  handleMinutesChange(Number(time.minutes) + 10)
                }
              }}
            >
              <IconArrowUp />
            </span>

            <span
              className="arrows__down"
              onClick={() => {
                if (time.minutes <= 0) {
                  setTime({ ...time, minutes: 50 })
                  handleMinutesChange(50)
                } else {
                  setTime({ ...time, minutes: Number(time.minutes) - 10 })
                  handleMinutesChange(Number(time.minutes) - 10)
                }
              }}
            >
              <IconArrowDown />
            </span>
          </div>
        </div>
      </div>

      <span onClick={() => setFocus(false)}>
        <IconClose />
      </span>
    </div>
  )
}

export default CustomTimeInput
