import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getEventByHash } from "@/redux/slices/events/eventsSlice"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import {
  IconConfirm,
  IconDatePicker,
  IconFailed,
  IconLocation,
} from "@/components/utils/Icons/CustomIcons"
import { useParams } from "react-router-dom"
import AcceptModal from "@/components/Register/AcceptModal/AcceptModal"
import DeclineModal from "@/components/Register/DeclineModal/DeclineModal"
import ThankModal from "@/components/Register/ThankModal/ThankModal"
import Preloader from "@/components/utils/Preloader/Preloader"
import dayjs from "dayjs"
import "./RegisterPage.scss"

const RegisterPage = () => {
  const dispatch = useAppDispatch()
  const { not_found, isLoading, event_by_hash } = useAppSelector(
    (state) => state.events,
  )
  const [showAccept, setShowAccept] = useState(false)
  const [showDecline, setShowDecline] = useState(false)
  const [showThank, setShowThank] = useState(false)
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 })
  const [styles, setStyles] = useState({})
  const { event_hash } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    event_hash && dispatch(getEventByHash(event_hash))
  }, [dispatch, event_hash])

  const handleImageLoad = (e: any) => {
    const { width, height } = e.target
    setImgDimensions({ width, height })
  }

  useEffect(() => {
    if (imgDimensions.width > 0 && imgDimensions.height > 0) {
      if (imgDimensions.height > imgDimensions.width) {
        setStyles({ height: "100%", width: "auto" })
      } else {
        setStyles({ width: "100%", height: "auto" })
      }
    }
  }, [imgDimensions])

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : not_found ? (
        <div className="register-not">
          <img
            className="register-not__picture"
            src="/images/event-not-found.png"
            alt="Picture"
          />

          <h2 className="caption register-not__caption">
            {t("register.notFound")}
          </h2>
          <h4 className="caption register-not__description">
            {t("register.notFoundDesc")}
          </h4>
        </div>
      ) : (
        <div className="register">
          <div className="register-wrapper">
            <div className="register-img">
              <img
                src={event_by_hash.event_image}
                alt="background"
                style={styles}
                onLoad={handleImageLoad}
              />
            </div>
            <div className="register-main">
              <div className="register-main__bottom">
                <div className="register-main__content">
                  <p className="register-main__description">
                    {event_by_hash.event_contact_accepted === true ? (
                      <IconConfirm />
                    ) : event_by_hash.event_contact_accepted === false ? (
                      <IconFailed />
                    ) : (
                      ""
                    )}{" "}
                    {t("register.title")}
                  </p>
                  <span className="register-main__title">
                    {event_by_hash.name}
                  </span>
                </div>
                <div className="d-flex register-main__card">
                  <IconDatePicker />
                  <div className="register-main__card-description">
                    <p className="register-main__card-caption">{t("register.date")}</p>
                    <p className="register-main__card-place">
                      {dayjs(event_by_hash.start_datetime).format(
                        "dddd, DD MMM YYYY",
                      )}
                    </p>
                    <p className="register-main__card-address">
                      {dayjs(event_by_hash.start_datetime).format("h:mm")}{" "}
                      {dayjs(event_by_hash.start_datetime)
                        .format("a")
                        .toLowerCase()
                        .replace(/m$/, ".m.")}
                    </p>
                  </div>
                </div>
                {event_by_hash.location &&
                  (JSON.parse(event_by_hash.location).venue !== undefined ||
                    JSON.parse(event_by_hash.location).address !== "") && (
                    <div className="d-flex register-main__card">
                      <IconLocation />
                      <div className="register-main__card-description">
                        <p className="register-main__card-caption">{t("register.location")}</p>
                        {event_by_hash.venue !== "" && (
                          <p className="register-main__card-place">
                            {event_by_hash.venue}
                          </p>
                        )}
                        <a
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          href={
                            event_by_hash.location &&
                            JSON.parse(event_by_hash.location).lat
                              ? `https://www.google.com/maps?q=${
                                  JSON.parse(event_by_hash.location).lat
                                },${JSON.parse(event_by_hash.location).lng}`
                              : "#"
                          }
                          className="register-main__card-address"
                        >
                          {event_by_hash.location &&
                            JSON.parse(event_by_hash.location).address}
                        </a>
                      </div>
                    </div>
                  )}
                {event_by_hash.event_contact_accepted === null && (
                  <div className="d-flex gap-3 register-main__buttons">
                    <button
                      className="btn btn-simple"
                      onClick={() => setShowDecline(true)}
                    >
                      {t("buttons.decline")}
                    </button>
                    <button
                      className="btn btn-dark"
                      onClick={() => setShowAccept(true)}
                    >
                      {t("buttons.accept")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <AcceptModal
            show={showAccept}
            setShow={setShowAccept}
            setShowThank={setShowThank}
          />
          <DeclineModal show={showDecline} setShow={setShowDecline} />
          <ThankModal show={showThank} setShow={setShowThank} />
        </div>
      )}
    </>
  )
}

export default RegisterPage
