import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../redux/store"
import { getContactsOnEvent } from "../../../redux/slices/contacts/contactsSlices"
import ChooseFileImport from "./ChooseFileImport"
import {
  IconArrowDownSmall,
  IconLoadSmall,
} from "../../utils/Icons/CustomIcons"
import styles from "./ImportGuest.module.scss"

const ImportGuest = ({ file, setFile, show, handleClose, filter }: any) => {
  const { t } = useTranslation()
  const [showExample, setShowExample] = useState(false)
  const [progress, setProgress] = useState(0)
  const dispatch = useAppDispatch()

  const goToEvent = () => {
    dispatch(getContactsOnEvent(filter))
    handleClose()
    setFile(null)
    setProgress(0)
  }

  useEffect(() => {
    setProgress(progress)
  }, [])

  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  // const fileExtension = ".xlsx"

  // const exportToCSV = (csvData: any, fileName: string) => {
  //   const ws = XLSX.utils.json_to_sheet(csvData)
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  //   const data = new Blob([excelBuffer], { type: fileType })
  //   FileSaver.saveAs(data, fileName + fileExtension)
  // }

  return (
    <Modal className={`${styles.importGuest} import-guest__modal ${
      progress >= 100 ? "small" : ""
    } contacts`} show={show} onHide={goToEvent}>
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body className="body">
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("contacts.importGuestPopup.title")}
          </h2>

          <h4
            className={styles.uploadedText}
            style={{
              display:
                progress >= 100
                  ? window.innerWidth > 1279
                    ? "flex"
                    : "none"
                  : "none",
            }}
          >
            {t("contacts.importGuestPopup.upload")}
          </h4>

          <div className={styles.choose} style={
              progress >= 10 ? { marginBottom: "0" } : { textAlign: "center" }
            }>
            <ChooseFileImport
              progress={progress}
              setProgress={setProgress}
              file={file}
              setFile={setFile}
              goToEvent={goToEvent}
            />
            {progress >= 100 && (
              <button
                onClick={goToEvent}
                className={`btn btn-dark ${styles.choose__btn}`}
              >
                {t("buttons.goToContacts")}
              </button>
            )}
          </div>

          {!file && (
            <div className={styles.templateTable}>
              <div className={styles.templateTable__line}>
                <span className={styles.templateTable__text}>
                  {t("contacts.example.text")}
                </span>
                <a href={"./files/import_guests_example.xlsx"} target="_blank">
                  <div className={styles.templateTable__link}>
                    {t("contacts.example.linkDownload")} <IconLoadSmall />
                  </div>
                </a>
              </div>

              <div className={styles.or}>
                <span className={styles.or__text}>
                  {t("contacts.example.or")}
                </span>
              </div>

              <div className={styles.templateTable__line}>
                <span className={styles.templateTable__text}>
                  {t("contacts.example.text2")}
                </span>
                <span
                  className={
                    showExample
                      ? `${styles.templateTable__link} ${styles.templateTable__link_show}`
                      : `${styles.templateTable__link}`
                  }
                  onClick={() => {
                    setShowExample(!showExample)
                  }}
                >
                  {t("contacts.example.seeExample")} <IconArrowDownSmall />
                </span>
              </div>

              {showExample && (
                <div className={styles.importGuest__image}>
                  <img src="./images/pic-example.jpg" alt="" />
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ImportGuest
