import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import styles from "./AboutModal.module.scss"

const AboutModal = ({ show, setShow }: any) => {
  const { t } = useTranslation()
  const onHide = () => {
    setShow(!show)
  }

  return (
    <Modal
      className={`${styles.testModal} about-modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`${styles.caption}`}>{t("openModal.about")}</h2>
          <p className={styles.text}>{t("openModal.desc")}</p>
          <div className={styles.section}>
            <ul>
              <p className={styles.title}>{t("openModal.free")}</p>
              <li className={styles.element}>{t("openModal.registrations")}</li>
              <li className={styles.element}>{t("openModal.oneQuestion")}</li>
            </ul>
            <ul>
              <p className={styles.title}>{t("openModal.premium")}</p>
              <li className={styles.element}>
                {t("openModal.registrations10000")}
              </li>
              <li className={styles.element}>{t("openModal.questions")}</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AboutModal
