import { useTranslation } from "react-i18next"
import styles from "../../QrDesignEditor.module.scss"
import { useActions } from "@/hooks/useActions"

const QrDesignEditorButton = ({
  icon,
  text,
  setTextVisible,
  textVisible,
  setQrVisible,
  setCountVisible,
  setSelectVisible,
  selectVisible,
  qrVisible,
  countVisible,
  onReset,
  setShow,
  setUpdateText,
  updateText,
}: {
  icon: string
  text: string
  textVisible?: boolean
  setTextVisible?: any
  setQrVisible?: any
  setCountVisible?: any
  setSelectVisible?: any
  selectVisible?: any
  qrVisible?: any
  countVisible?: any
  onReset?: any
  setShow: any
  setUpdateText?: any
  updateText?: boolean
}) => {
  const { t } = useTranslation()
  const { toggleCropModalShow } = useActions()

  const handleClick = (func: any, value: any) => {
    func(!value)
    setShow(true)
  }

  return (
    <div className={styles.heading_editor_btn}>
      {text === t("qr.menu.crop") ? (
        <button
          onClick={() => toggleCropModalShow()}
          data-bs-toggle="modal"
          data-bs-target="#cropModal"
        >
          <img src={icon} alt="" />
        </button>
      ) : text === t("qr.menu.text") ? (
        <button
          onClick={() => {
            handleClick(setTextVisible, textVisible)
            setUpdateText(!updateText)
          }}
        >
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : text === t("qr.menu.qr") ? (
        <button onClick={() => handleClick(setQrVisible, qrVisible)}>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : text === t("qr.menu.amount") ? (
        <button onClick={() => handleClick(setCountVisible, countVisible)}>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : text === t("qr.menu.reset") ? (
        <button onClick={() => onReset(true)}>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : text === t("qr.menu.name") ? (
        <button onClick={() => handleClick(setSelectVisible, selectVisible)}>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      ) : (
        <button>
          {" "}
          <img src={icon} alt="" />{" "}
        </button>
      )}
      <p>{text}</p>
    </div>
  )
}
export default QrDesignEditorButton
