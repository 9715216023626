import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import {
  getConversation,
  markAsRead,
} from "../../redux/slices/messages/messagesSlice"
import { useAppDispatch } from "../../redux/store"

type Props = {
  avatar: string
  id: number
  lastName: string
  name: string
  text: string
  time: string
  count: number
  setRight: any
  setEventId: any
  eventId: number
  setContactId: any
  scrollChat: any
  setName: any
  send: boolean
  setCanSend: any
}

const MessageCard = ({
  avatar,
  id,
  lastName,
  name,
  text,
  time,
  count,
  setRight,
  setEventId,
  eventId,
  setContactId,
  scrollChat,
  setName,
  send,
  setCanSend,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [timeMessage, setTimeMessage] = useState(dayjs(time).format("DD.MM.YYYY"))

  const onClick = () => {
    dispatch(getConversation(id))
    dispatch(markAsRead(id))
    setName(`${name} ${lastName}`)
    setEventId(eventId)
    setRight(true)
    setContactId(id)
    window.scrollTo(0, 1000)
    scrollChat()
    setCanSend(send)
  }

  useEffect(() => {
    setInterval(() => {
      getTime(new Date())
    }, 10000)
  }, [])

  const getTime = (date: any) => {
    const minutes =
      Math.round(Date.parse(date.toString()) / 60000) -
      Math.round(Date.parse(time) / 60000)
    const hours =
      Math.round(Date.parse(date.toString()) / 3600000) -
      Math.round(Date.parse(time) / 3600000)
    if (minutes < 60) {
      setTimeMessage(`${minutes}m`)
    } else if (hours < 24) {
      setTimeMessage(`${hours}h`)
    } else {
      setTimeMessage(dayjs(time).format("DD.MM.YYYY"))
    }
  }

  return (
    <div
      className={`messages-card ${count > 0 ? "messages-card__bordered" : ""}`}
      onClick={onClick}
    >
      <img src={avatar} alt="Avatar" className="messages-card__avatar" />
      <div className="messages-card__description">
        <span className="messages-card__name">
          {name} {lastName}
        </span>
        <span
          className={`messages-card__message ${
            text !== "" ? "" : "messages-card__message-empty"
          }`}
        >
          {text !== "" ? text : t("messages.noMessagesYet")}
        </span>
      </div>
      <div
        className={` ${
          count !== 0 ? "messages-card__count" : "messages-card__time"
        }`}
      >
        {count !== 0 ? count : timeMessage}
      </div>
    </div>
  )
}

export default MessageCard
