import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import {
  IconBtnCreate,
  IconImport,
  IconOptions,
} from "../utils/Icons/CustomIcons"

const ActionsPopup = ({ show, setShow, setImportShow }: any) => {
  const { t } = useTranslation()
  return (
    <Dropdown className="dropdown-block dropdown--not-arrow dropdown-filter actions-popup">
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent event-page__btn">
        <IconOptions />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setShow(!show)}>
          <IconBtnCreate />
          <span className="dropdown-text">{t("selectContacts.create")}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setImportShow(true)}>
          <IconImport />
          <span className="dropdown-text">{t("selectContacts.import")}</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionsPopup
