import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import { getTestInvitation } from "../../redux/slices/events/eventsSlice"
import StatusOk from "../../components/Test/StatusOk"
import StatusNo from "../../components/Test/StatusNo"
import { IconArrowLeft } from "../../components/utils/Icons/CustomIcons"
import Preloader from "../../components/utils/Preloader/Preloader"

const TestInvitationStatusPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const {
    isLoading,
    error_text,
    message_status,
    invitation_status,
    isTimeout,
  } = useAppSelector((state) => state.events)

  useEffect(() => {
    isTimeout
      ? setTimeout(() => dispatch(getTestInvitation(Number(event_id))), 3000)
      : dispatch(getTestInvitation(Number(event_id)))
  }, [])

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="test-section">
          <div className="container">
            <div className="event-page__link">
              <Link
                className="d-flex align-items-center event-page__link-container"
                to={`/${event_id}`}
              >
                <IconArrowLeft />
                <span className="event-page__link-text">{t("test.back")}</span>
              </Link>
            </div>
            <div className="title-page">
              <h2 className="caption-two">{t("test.titlePageTest")}</h2>
            </div>
            {error_text ? (
              <StatusNo />
            ) : (
              (invitation_status !== 9 || message_status !== 0) && <StatusOk />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default TestInvitationStatusPage
