import ContactsHead from "../../components/SelectContacts/ContactsHead"
import ContactsBody from "../../components/SelectContacts/ContactsBody"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  deleteContacts,
  setLoading,
  setCurrentPage,
  getContacts,
} from "../../redux/slices/contacts/contactsSlices"
import Preloader from "../../components/utils/Preloader/Preloader"
import { ToastContainer, toast } from "react-toastify"

const SelectContactsPage = () => {
  const { data, isLoading, current_page, limit, total_pages } = useAppSelector(
    (state) => state.contacts,
  )
  const dispatch = useAppDispatch()

  const [dataLoadMore, setDataLoadMore] = useState([])
  const [addShow, setAddShow] = useState(false)
  const [importShow, setImportShow] = useState(false)
  const [checked, setChecked] = useState([])

  const [filter, setFilter] = useState({
    sort: "",
    searchValue: "",
    order: ''
  })

  const notify = (text: string) => {
    toast.error(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  const deleteQuest = (id: any) => {
    dispatch(deleteContacts([id])).then(() => {
      dispatch(getContacts(filter))
      setChecked([])
    })
  }

  useEffect(() => {
    dispatch(setLoading())
    dispatch(getContacts(filter))
  }, [])

  // useEffect(() => {
  //   setDataLoadMore([...dataLoadMore, ...data]) MOBILE NOT WORK
  // }, [data])

  useEffect(() => {
    dispatch(setCurrentPage(current_page))
    dispatch(getContacts(filter))
  }, [filter.sort, filter.searchValue, filter.order, current_page])

  return (
    <>
      {!isLoading ? (
        <div className="contacts-page">
          <div className="container">
            <ContactsHead
              filter={filter}
              setFilter={setFilter}
              setAddShow={setAddShow}
              setImportShow={setImportShow}
              addShow={addShow}
              checked={checked}
              setChecked={setChecked}
              notify={notify}
              guests={data}
            />

            <ContactsBody
              guests={data}
              limit={limit}
              current_page={current_page}
              total_pages={total_pages}
              filter={filter}
              setFilter={setFilter}
              deleteQuest={deleteQuest}
              dataLoadMore={dataLoadMore}
              setDataLoadMore={setDataLoadMore}
              addShow={addShow}
              setAddShow={setAddShow}
              importShow={importShow}
              setImportShow={setImportShow}
              checked={checked}
              setChecked={setChecked}
            />
            <ToastContainer
              style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
            />
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  )
}

export default SelectContactsPage
