import { Layer, Text } from "react-konva"
import Konva from "konva"
import { URLImage } from "./QRCodeDownLoad"
import { QRCodeCountBtn } from "./QRCodeCountBtn"

const QrCodeCount = ({
  show,
  imageClose,
  countObj,
  handleKeyDown,
  setCountObj,
  handleTextDblClick,
  imageEdit,
  handleDragEndCount,
  countRef,
}: any) => {
  return (
    <Layer
      draggable={countObj.textEditVisible ? true : false}
      opacity={countObj.textEditVisible && show === false ? 0 : 1}
      onDragEnd={handleDragEndCount}
    >
      <URLImage
        opacity={countObj.textEditVisible ? 1 : 0}
        src={imageClose}
        x={countObj.x}
        y={countObj.y - 42}
        funcClick={handleKeyDown}
        width={22}
        height={22}
      />
      <Text
        fontFamily={countObj.fontFamily}
        fontStyle={countObj.fontStyle}
        fontSize={countObj.fontSize}
        textDecoration={countObj.textDecoration}
        align={countObj.align}
        y={countObj.y}
        x={countObj.x}
        ref={countRef}
        fill={countObj.fill}
        text={countObj.textValue}
        wrap="word"
        width={countObj.width}
        height={countObj.height}
        onDblTap={(e) => handleTextDblClick(e, countObj, setCountObj)}
        onDblClick={(e) => handleTextDblClick(e, countObj, setCountObj)}
        sceneFunc={(context, shape) => {
          const radius = 4
          context.fillStyle = countObj.color
          context.beginPath()
          context.moveTo(0 + radius, -15)
          context.lineTo(0 + shape.width() - radius, -15)
          context.arcTo(
            0 + shape.width(),
            -15,
            0 + shape.width(),
            -15 + radius,
            radius,
          )
          context.lineTo(0 + shape.width(), -15 + shape.height() - radius)
          context.arcTo(
            0 + shape.width(),
            -15 + shape.height(),
            0 + shape.width() - radius,
            -15 + shape.height(),
            radius,
          )
          context.lineTo(0 + radius, -15 + shape.height())
          context.arcTo(
            0,
            -15 + shape.height(),
            0,
            -15 + shape.height() - radius,
            radius,
          )
          context.lineTo(0, -15 + radius)
          context.arcTo(0, -15, 0 + radius, -15, radius)
          context.closePath()
          context.fill()
          ;(shape as Konva.Text)._sceneFunc(context)
        }}
      />
      <QRCodeCountBtn
        opacity={countObj.textEditVisible ? 1 : 0}
        src={imageEdit}
        x={countObj.x + countObj.width - 22}
        y={countObj.y + countObj.height - 10}
        width={22}
        height={22}
        draggable={true}
        qrBlock={countObj}
        setQrBlock={setCountObj}
      />
    </Layer>
  )
}

export default QrCodeCount
