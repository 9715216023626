export default function ColorPeekIconQR({
  color = "black",
}: {
  color: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_642_2984)">
        <path d="M0 20H24V24H0V20Z" fill={color} fillOpacity="1" />
        <path
          d="M7.37576 5.33333H8.9596V6.88889H7.37576V5.33333Z"
          fill="black"
        />
        <path
          d="M5 9.22222V3H11.3354V9.22222H5ZM6.58384 4.55556V7.66667H9.75151V4.55556H6.58384Z"
          fill="black"
        />
        <path
          d="M12.9192 10.7778H15.2949V12.3333H12.9192V10.7778Z"
          fill="black"
        />
        <path
          d="M15.2949 12.3333H16.8788V10.7778H19.2545V13.1111H16.8788V13.8889H17.6707V14.6667H19.2545V17H17.6707V15.4444H16.0869V17H12.9192V13.8889H14.503V15.4444H15.2949V12.3333Z"
          fill="black"
        />
        <path
          d="M16.8788 5.33333H15.2949V6.88889H16.8788V5.33333Z"
          fill="black"
        />
        <path
          d="M12.9192 3V9.22222H19.2545V3H12.9192ZM17.6707 4.55556V7.66667H14.503V4.55556H17.6707Z"
          fill="black"
        />
        <path
          d="M7.37576 13.1111H8.9596V14.6667H7.37576V13.1111Z"
          fill="black"
        />
        <path
          d="M5 17V10.7778H11.3354V17H5ZM6.58384 12.3333V15.4444H9.75151V12.3333H6.58384Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_642_2984">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
