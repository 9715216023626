export default function ColorPeekIconText({
  color = "black",
}: {
  color: string
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_595_4432)">
        <path
          d="M0 20H24V24H0V20Z"
          fill={color}
          fillOpacity={color === "black" ? 0.36 : 1}
        />
        <path
          d="M11 3L5.5 17H7.75L8.875 14H15.125L16.25 17H18.5L13 3H11ZM9.625 12L12 5.665L14.375 12H9.625Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_595_4432">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
