import { useState, useEffect } from "react"
import { FileUploader } from "react-drag-drop-files"
import socketio from "socket.io-client"
import Echo from "laravel-echo"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import { importGuests } from "../../../redux/slices/contacts/contactsSlices"
import {
  IconCompletedFile,
  IconDeleteFile,
  IconLoadFile,
  IconisLoadingFile,
} from "../../utils/Icons/CustomIcons"
import { iFile } from "../types"

const filesType = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]

const ChooseFileImport = ({
  file,
  setFile,
  progress,
  setProgress,
  goToEvent,
}: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const { accessToken } = useAppSelector((state) => state.auth)
  const { progress_id } = useAppSelector((state) => state.contacts)

  const clearFile = () => {
    setProgress(0)
    setFile(null)
    setError("")
    setLoading(false)
  }

  const handleError = () => {
    setError(t("contacts.dragAndDrop.error"))
    setLoading(false)
  }

  const handleChange = (file: iFile) => {
    const formData: any = new FormData()
    let { size, type } = file

    size = Number((size / 1024).toFixed(0))
    formData.append("file", file)
    formData.append("event_id", event_id)

    setLoading(true)

    if (filesType.includes(type)) {
      setError("")
      setFile(file)
      dispatch(importGuests(formData))
    } else {
      handleError()
      setFile(null)
    }
  }

  // socket

  useEffect(() => {
    const echo = new Echo({
      host: "https://dev-api.mazoom.sa:6001",
      broadcaster: "socket.io",
      client: socketio,
      encrypted: false,
      transports: ["websocket"],
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    })

    if (progress_id) {
      echo
        .channel(`private-file-upload-progress.${progress_id}`)
        .listen(".file-upload-progress.changed", (ev: any) => {
          if (!ev.messageBag) {
            setProgress(ev.fileUploadProgress.progress_pct)
            setLoading(false)
          } else {
            setFile(null)
            setError((Object.values(ev.messageBag) as string[][])[0][0])
          }
        })
    }

    return () => {
      if (progress_id) {
        echo.leaveChannel(`private-file-upload-progress.${progress_id}`)
      }
    }
  }, [progress_id])

  // socket

  return (
    <>
      {!file ? (
        error ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "40px" }}
          >
            {
              <span
                style={{
                  minHeight: "34px",
                  fontFamily: "Abel",
                  fontSize: "24px",
                  lineHeight: "140%",
                }}
                className="error"
              >
                {error}
              </span>
            }
            <div
              style={{ display: "flex", justifyContent: "center", gap: "16px" }}
            >
              <span className="btn btn-simple" onClick={goToEvent}>
                {t("buttons.cancel")}
              </span>
              <button className="btn btn-dark" onClick={() => setError("")}>
                {t("buttons.uploadAnother")}
              </button>
            </div>
          </div>
        ) : (
          <div className="loadFile">
            <FileUploader
              name="file"
              maxSize={5}
              types={["csv", "xlsx", "xls"]}
              multiple={false}
              handleChange={handleChange}
              onTypeError={handleError}
              onSizeError={handleError}
              required={true}
            >
              <div className="loadFile-text">
                <IconLoadFile />
                <span className="title">{t("contacts.dragAndDrop.title")}</span>
                <span className="desc">{t("contacts.dragAndDrop.desc")}</span>
                <span className="text-required">
                  {t("contacts.dragAndDrop.text")}
                </span>
                {error && (
                  <span className="error">
                    {t("test.error")} {error}
                  </span>
                )}
              </div>
            </FileUploader>
          </div>
        )
      ) : (
        <div className="loadFileComplated">
          {loading ? (
            <IconisLoadingFile />
          ) : (
            <button className="btn-detele-file" onClick={clearFile}>
              <IconDeleteFile />
            </button>
          )}
          <div className="loadFileComplated__left">
            <span className="name">{file?.name}</span>
            <span className="size">{(file?.size / 1024).toFixed(0)}KB</span>
            <span className="file-status">
              {loading
                ? `${progress.toFixed(0)} ${t("contacts.dragAndDrop.left")}`
                : t("contacts.dragAndDrop.done")}
            </span>
          </div>
          <div className="loadFileComplated__right">
            <span className="percent">{`${progress}%`}</span>
            {!loading && <IconCompletedFile />}
          </div>
          <div className="loadFileProgress">
            <div
              className={loading ? "progress" : "progress progress--load"}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}
    </>
  )
}

export default ChooseFileImport
