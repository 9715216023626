import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import styles from "./ResetModal.module.scss"

const ResetModal = ({ show, setShow, onReset }: any) => {
  const { t } = useTranslation()
  return (
    <Modal
      className={`${styles.addPopup} delete-qr-modal`}
      show={show}
      onHide={() => setShow(!show)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>{t("qr.discard")}</h2>

          <div className={styles.controls}>
            <span className="btn btn-simple" onClick={onReset}>
              {t("buttons.yes")}
            </span>
            <button onClick={() => setShow(!show)} className="btn btn-dark">
              {t("buttons.no")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ResetModal
