import React from "react"
import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  deleteGuest,
  getContactsOnEvent,
  setCurrentPage,
  setLimit,
  setLoading,
} from "../../redux/slices/contacts/contactsSlices"
import { HiOutlineClock } from "react-icons/hi"
import {
  getConversation,
  getMessagesWithParams,
  markAsRead,
  setNewUser,
} from "@/redux/slices/messages/messagesSlice"
import { useTranslation } from "react-i18next"
import {
  IconChat,
  IconConfirm,
  IconContactDelete,
  IconFailed,
  IconMessageGray,
  IconOval,
  IconReject,
  IconScanned,
  IconSort,
  IconSorting,
  IconWaiting,
} from "../utils/Icons/CustomIcons"
import GuestActionsPopup from "./GuestActionsPopup"
import HoverTooltip from "./HoverTooltip"
import Preloader from "../utils/Preloader/Preloader"

const GuestsList = ({
  filter,
  setFilter,
  limit,
  checked,
  setChecked,
  allGuestsChecked,
  setAllGuestsChecked,
  editPopupOpen,
  editShow,
  notify,
  show,
  setShow,
  width,
}: any) => {
  const { t, i18n } = useTranslation()
  const { isLoading, data_uploaded, current_page } = useAppSelector(
    (state) => state.contacts,
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const [countContacts, setCountContacts] = useState(limit)

  const onDeleteGuest = () => {
    const arr = checked.map((item: number) => ({ contact_id: item }))
    dispatch(deleteGuest({ eventId: event_id, guestId: arr })).then(
      (result: any) => {
        if (result.error) {
          setChecked([])
          setAllGuestsChecked([])
        } else {
          dispatch(setLoading())
          dispatch(getContactsOnEvent(filter))
          setChecked([])
          setAllGuestsChecked([])
          notify(t("notify.deleted"), false)
        }
      },
    )
  }

  const changeFilter = (sort: string, order: string) => {
    setFilter({ ...filter, sort: sort, order: order })
  }

  useEffect(() => {
    dispatch(setLoading())
    dispatch(setCurrentPage(current_page))
    dispatch(getContactsOnEvent(filter))
    checked
  }, [
    filter.sort,
    filter.order,
    filter.searchValue,
    filter.statusNumber,
    current_page,
  ])

  const getStatus = (statusNumber: any) => {
    switch (statusNumber) {
      case 0:
        return t("event.stats.waiting")
        break
      case 1:
        return t("event.stats.going")
        break
      case 2:
        return t("event.stats.decline")
        break
      case 4:
        return t("event.stats.scanned")
        break
      case 5:
        return t("event.stats.visited")
        break
      case 6:
        return t("event.stats.failed")
        break
      case 7:
        return t("event.stats.notInvitedYet")
        break
      default:
        return ""
        break
    }
  }

  const getStatusIcon = (statusId: any) => {
    switch (statusId) {
      case 0:
        return <IconWaiting />
        break
      case 1:
        return <IconConfirm />
        break
      case 2:
        return <IconReject />
        break
      case 4:
        return <IconScanned />
        break
      case 5:
        return <IconOval />
        break
      case 6:
        return <IconFailed />
        break
      case 7:
        return <HiOutlineClock />
        break
      default:
        return ""
        break
    }
  }

  const onChoose = (guest: any) => {
    navigate("/messages")
    dispatch(getMessagesWithParams())
      .then(() => {
        dispatch(
          setNewUser({
            eventId: Number(event_id),
            contactId: guest.id,
            first_name: guest.first_name,
            last_name: guest.last_name,
            suffix: guest.suffix,
            email: guest.email,
            phone: guest.phone,
          }),
        )
      })
      .then(() => {
        dispatch(getConversation(guest.id))
        dispatch(markAsRead(guest.id))
      })
  }

  return (
    <div className="guests-body__wrapper">
      <div className="guests-body">
        <div
          className={`d-flex event-guests__buttons ${
            checked.length === 0 && "buttons-visible"
          }`}
        >
          <button
            className="btn btn-simple event-guests__invite-btn"
            onClick={() => setShow(!show)}
          >
            <IconMessageGray /> 
            <span className="ms-2">{t("event.sendInviteToSelected")}</span>
          </button>
          <button
            className="btn btn-simple event-guests__delete-btn"
            onClick={onDeleteGuest}
          >
            <IconContactDelete />
            <span className="ms-2">{t("event.deleteSelected")}</span>
          </button>
        </div>
        <div
          className={`guests-body__controls ${
            checked.length === 0 && "controls-visible"
          }`}
        >
          <Dropdown className="dropdown-block sorting-mobile">
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              <span className="sorting-mobile__text">
                {t("guests.sorting.title")} <IconSorting />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=first_name",
                  )
                }
              >
                {t("guests.sorting.name")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=email",
                  )
                }
              >
                {t("guests.sorting.email")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=phone",
                  )
                }
              >
                {t("guests.sorting.phoneNumber")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=quantity_members",
                  )
                }
              >
                {t("guests.sorting.invites")}
              </Dropdown.Item>
              <Dropdown.Item>{t("guests.sorting.calledNotes")}</Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=status",
                  )
                }
              >
                {t("guests.sorting.status")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {
            <Dropdown className="dropdown-block show-count">
              <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
                {t("contacts.showTitle")}
                {` ${countContacts}`}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(30)
                    dispatch(setLimit(30))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 30
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(60)
                    dispatch(setLimit(60))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 60
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
      </div>
      <>
        <div className="guests-table">
          <div className="guests-table__head">
            <div className="guests-table__checkbox">
              <input
                type="checkbox"
                id="all"
                checked={allGuestsChecked.length === limit}
                onChange={() => {
                  if (allGuestsChecked.length > 0) {
                    setAllGuestsChecked([])
                    setChecked([])
                  } else {
                    let allGuests: any[] = []
                    data_uploaded.forEach((element) => {
                      allGuests.push(element.id)
                    })
                    setAllGuestsChecked([...allGuests])
                    setChecked([...allGuests])
                  }
                }}
              />
              <label htmlFor="all"></label>
            </div>
            <div
              className="guests-table__col guests-table__col-name"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=first_name",
                )
              }
            >
              {t("contacts.sorting.name")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-email"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=email",
                )
              }
            >
              {t("contacts.sorting.email")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-phone"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=phone",
                )
              }
            >
              {t("contacts.sorting.phoneNumber")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-invites"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=quantity_members",
                )
              }
            >
              {t("contacts.sorting.invites")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div className="guests-table__col guests-table__col-notes">
              {t("contacts.sorting.calledNotes")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-status"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=status",
                )
              }
            >
              {t("contacts.sorting.status")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div className="guests-table__col">
              {t("contacts.sorting.actions")}
            </div>
          </div>
          {isLoading ? (
            <Preloader />
          ) : (
            <div className="guests-table__body">
              {data_uploaded &&
                data_uploaded.map((guest) => {
                  return (
                    <div className="guests-table__row" key={guest.id}>
                      <div className="guests-table__checkbox">
                        <input
                          type="checkbox"
                          id={`guest${guest.id}`}
                          checked={
                            allGuestsChecked.includes(guest.id) ||
                            checked.includes(guest.id)
                          }
                          onChange={() => {
                            if (checked.includes(guest.id)) {
                              let newChecked = checked.filter(
                                (chk: any) => chk !== guest.id,
                              )
                              setAllGuestsChecked([])
                              setChecked([...newChecked])
                            } else {
                              setChecked([...checked, guest.id])
                            }
                          }}
                        />
                        <label htmlFor={`guest${guest.id}`}></label>
                      </div>
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip
                          text={
                            guest.suffix !== ""
                              ? `${guest.suffix}. ${guest.first_name} ${guest.last_name}`
                              : `${guest.first_name}`
                          }
                          id={"t-2"}
                        >
                          <div className="guests-table__col guests-table__col-name">
                            <span className="name">
                              {guest.suffix !== ""
                                ? `${guest.suffix}. ${guest.first_name} ${guest.last_name}`
                                : `${guest.first_name}`}
                            </span>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div className="guests-table__col guests-table__col-name">
                          <span className="name">
                            {guest.suffix !== ""
                              ? `${guest.suffix}. ${guest.first_name} ${guest.last_name}`
                              : `${guest.first_name}`}
                          </span>
                        </div>
                      )}
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip text={guest.email} id={"t-3"}>
                          <div className="guests-table__col guests-table__col-email">
                            <a href={`mailto:${guest.email}`} className="mail">
                              {guest.email ? guest.email : "-"}
                            </a>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div className="guests-table__col guests-table__col-email">
                          <a href={`mailto:${guest.email}`} className="mail">
                            {guest.email ? guest.email : "-"}
                          </a>
                        </div>
                      )}
                      <div className="guests-table__col guests-table__col-phone">
                        <span className="phone">
                          {guest.phone ? guest.phone.split("|")[0] : "-"}
                        </span>
                      </div>

                      <div className="guests-table__col guests-table__col-invites">
                        <span className="invites">
                          {window.screen.width < 992 && "Invites:"}{" "}
                          {guest.quantity_members
                            ? guest.quantity_members + 1
                            : 1}
                        </span>
                      </div>
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip
                          text={
                            i18n.language === "en"
                              ? typeof guest.called_note_en === "string"
                                ? guest.called_note_en
                                : ""
                              : i18n.language === "ar"
                              ? typeof guest.called_note_ar === "string"
                                ? guest.called_note_ar
                                : ""
                              : ""
                          }
                          id={"t-3"}
                        >
                          <div
                            className={
                              "called__notes guests-table__col guests-table__col-notes"
                            }
                          >
                            {/*<IconAlertOctagon />*/}

                            {/* {guest.called_note_en === "Did not confirm!" && (
                            <IconAlertOctagon />
                          )}
                          {guest.called_note_en === "Invitation confirmed!" && (
                            <IconAlertCircle />
                          )} */}
                            <span className="ms-2 me-2">
                              {i18n.language === "en"
                                ? typeof guest.called_note_en === "string"
                                  ? guest.called_note_en
                                  : ""
                                : i18n.language === "ar"
                                ? typeof guest.called_note_ar === "string"
                                  ? guest.called_note_ar
                                  : ""
                                : ""}
                            </span>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div
                          className={
                            "called__notes guests-table__col guests-table__col-notes"
                          }
                        >
                          {/*<IconAlertCircle />*/}
                          {/* {guest.called_note_en === "Did not confirm!" && (
                          <IconAlertOctagon />
                        )}
                        {guest.called_note_en === "Invitation confirmed!" && (
                          <IconCheck />
                        )} */}
                          <span className="ms-2 me-2">
                            {i18n.language === "en"
                              ? typeof guest.called_note_en === "string"
                                ? guest.called_note_en
                                : ""
                              : i18n.language === "ar"
                              ? typeof guest.called_note_ar === "string"
                                ? guest.called_note_ar
                                : ""
                              : ""}
                          </span>
                        </div>
                      )}
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip text={getStatus(guest.status)} id={"t-7"}>
                          <div
                            className={`${
                              guest.status === 7 ? "going" : "scanned"
                            } guests-table__col guests-table__col-status`}
                          >
                            <IconOval />
                            <span className="guest-status">
                              {getStatus(guest.status)}
                            </span>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div
                          className={`${
                            guest.status === 7 ? "going" : "scanned"
                          } guests-table__col guests-table__col-status`}
                        >
                          {/*<IconOval />*/}
                          {getStatusIcon(guest.status)}
                          <span className="guest-status">
                            {getStatus(guest.status)}
                          </span>
                        </div>
                      )}
                      <div className="guests-table__col">
                        <div className="guests-change">
                          {guest.whatsapp_message_can_be_sent === false ? (
                            <HoverTooltip
                              text={t("tooltips.cannot")}
                              id={"t-22"}
                            >
                              <button className="btn btn-change">
                                <IconChat />
                              </button>
                            </HoverTooltip>
                          ) : (
                            <button
                              className="btn btn-change"
                              onClick={() => onChoose(guest)}
                            >
                              <IconChat />
                            </button>
                          )}
                          <GuestActionsPopup
                            editPopupOpen={editPopupOpen}
                            guest={guest}
                            filter={filter}
                            notify={notify}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </>
    </div>
  )
}

export default GuestsList
