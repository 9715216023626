import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getContactsOnEvent,
  setCurrentPage,
  setLimit,
  setLoading,
} from "../../redux/slices/contacts/contactsSlices"
import {
  IconAlertCircle,
  IconAlertOctagon,
  IconOval,
  IconReminderFalse,
  IconReminderTrue,
  IconSort,
  IconSorting,
} from "../utils/Icons/CustomIcons"
import HoverTooltip from "./HoverTooltip"

type Props = {
  filter: {
    sort: string
    order: string
    searchValue: string
    reminderNumber: number | string
    statusNumber: number | string
    id: number
  }
  setFilter: any
  limit: number
  checked: any
  setChecked: any
  allGuestsChecked: any
  setAllGuestsChecked: any
  width: number
}

const GuestsList = ({
  filter,
  setFilter,
  limit,
  checked,
  setChecked,
  allGuestsChecked,
  setAllGuestsChecked,
  width,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data_on_event } = useAppSelector((state) => state.contacts)
  const [countContacts, setCountContacts] = useState(limit)
  // const { balance } = useAppSelector((state) => state.auth)

  const changeFilter = (sort: string, order: string) => {
    setFilter({ ...filter, sort: sort, order: order })
  }

  useEffect(() => {
    dispatch(setLoading())
    dispatch(setCurrentPage(1))
    dispatch(getContactsOnEvent(filter))
    checked
  }, [
    filter.sort,
    filter.order,
    filter.searchValue,
    filter.statusNumber,
    filter.reminderNumber,
  ])

  const getStatus = (statusNumber: number) => {
    switch (statusNumber) {
      case 0:
        return t("reminders.filter.status.ignore")
        break
      case 1:
        return t("reminders.filter.status.going")
        break
      case 2:
        return t("reminders.filter.status.decline")
        break
      case 5:
        return t("reminders.filter.status.visited")
        break
      case 6:
        return t("reminders.filter.status.failed")
        break
      default:
        return ""
        break
    }
  }

  return (
    <div className="guests-body__wrapper" style={{ width: "100%" }}>
      <div className="guests-body">
        <div className={`guests-body__controls ${"controls-visible"}`}>
          <Dropdown className="dropdown-block sorting-mobile">
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              <span className="sorting-mobile__text">
                {t("guests.sorting.title")} <IconSorting />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=first_name",
                  )
                }
              >
                {t("guests.sorting.name")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=email",
                  )
                }
              >
                {t("guests.sorting.email")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=phone",
                  )
                }
              >
                {t("guests.sorting.phoneNumber")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=free_reminder",
                  )
                }
              >
                {t("guests.sorting.freeReminder")}
              </Dropdown.Item>
              <Dropdown.Item>{t("guests.sorting.calledNotes")}</Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=status",
                  )
                }
              >
                {t("guests.sorting.status")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {data_on_event.length > 0 && (
            <Dropdown className="dropdown-block show-count">
              <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
                {t("contacts.showTitle")}
                {` ${countContacts}`}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(30)
                    dispatch(setLimit(30))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 30
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(60)
                    dispatch(setLimit(60))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 60
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>

      {data_on_event.length !== 0 ? (
        <>
          <div className="guests-table">
            <div className="guests-table__head">
              <div className="guests-table__checkbox">
                <input
                  type="checkbox"
                  id="all"
                  checked={allGuestsChecked.length === limit}
                  onChange={() => {
                    if (allGuestsChecked.length > 0) {
                      setAllGuestsChecked([])
                      setChecked([])
                    } else {
                      let allGuests: any = []
                      data_on_event.forEach((element) => {
                        allGuests.push(element.id)
                      })
                      setAllGuestsChecked([...allGuests])
                      setChecked([...allGuests])
                    }
                  }}
                />
                <label htmlFor="all"></label>
              </div>
              <div
                className="guests-table__col guests-table__col-name"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=first_name",
                  )
                }
              >
                {t("contacts.sorting.name")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="guests-table__col guests-table__col-email"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=email",
                  )
                }
              >
                {t("contacts.sorting.email")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="guests-table__col guests-table__col-phone"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=phone",
                  )
                }
              >
                {t("contacts.sorting.phoneNumber")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="guests-table__col guests-table__col-reminders"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=free_reminder",
                  )
                }
              >
                {t("contacts.sorting.freeReminder")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div className="guests-table__col guests-table__col-notes">
                {t("contacts.sorting.calledNotes")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="guests-table__col guests-table__col-status"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=status",
                  )
                }
              >
                {t("contacts.sorting.status")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
            </div>
            <div className="guests-table__body">
              {data_on_event.map((guest) => {
                return (
                  <div className="guests-table__row" key={guest.id}>
                    <div className="guests-table__checkbox">
                      <input
                        type="checkbox"
                        id={`guest${guest.id}`}
                        checked={
                          allGuestsChecked.includes(guest.id) ||
                          checked.includes(guest.id)
                        }
                        onChange={() => {
                          if (checked.includes(guest.id)) {
                            let newChecked = checked.filter(
                              (chk: any) => chk !== guest.id,
                            )
                            setAllGuestsChecked([])
                            setChecked([...newChecked])
                          } else {
                            setChecked([...checked, guest.id])
                          }
                        }}
                      />
                      <label htmlFor={`guest${guest.id}`}></label>
                    </div>
                    {width > 991 ? (
                      <HoverTooltip
                        text={`${guest.suffix}. ${guest.first_name} ${guest.last_name}`}
                        id={"t-2"}
                      >
                        <div className="guests-table__col guests-table__col-name">
                          <span className="name">
                            {guest.suffix}. {guest.first_name} {guest.last_name}
                          </span>
                        </div>
                      </HoverTooltip>
                    ) : (
                      <div className="guests-table__col guests-table__col-name">
                        <span className="name">
                          {guest.suffix}. {guest.first_name} {guest.last_name}
                        </span>
                      </div>
                    )}
                    {width > 991 ? (
                      <HoverTooltip text={guest.email} id={"t-3"}>
                        <div className="guests-table__col guests-table__col-email">
                          <a href={`mailto:${guest.email}`} className="mail">
                            {guest.email ? guest.email : "-"}
                          </a>
                        </div>
                      </HoverTooltip>
                    ) : (
                      <div className="guests-table__col guests-table__col-email">
                        <a href={`mailto:${guest.email}`} className="mail">
                          {guest.email ? guest.email : "-"}
                        </a>
                      </div>
                    )}
                    <div className="guests-table__col guests-table__col-phone">
                      <span className="phone">
                      {guest.phone ? guest.phone.split("|")[0] : "-"}
                      </span>
                    </div>

                    <div className="guests-table__col guests-table__col-reminders">
                      <span className="reminders">
                        <span className="reminders-mobile">{t("testModal.free")}</span>
                        {guest.free_reminder === true ? (
                          <IconReminderTrue />
                        ) : (
                          <IconReminderFalse />
                        )}
                      </span>
                    </div>
                    {width > 991 ? (
                      <HoverTooltip
                        text={
                          typeof guest.called_note_en === "string"
                            ? guest.called_note_en
                            : ""
                        }
                        id={"t-3"}
                      >
                        <div
                          className={
                            "called__notes guests-table__col guests-table__col-notes"
                          }
                        >
                          <IconAlertOctagon />
                          <span className="ms-2">
                            {typeof guest.called_note_en === "string"
                              ? guest.called_note_en
                              : ""}
                          </span>
                        </div>
                      </HoverTooltip>
                    ) : (
                      <div
                        className={
                          "called__notes guests-table__col guests-table__col-notes"
                        }
                      >
                        <IconAlertCircle />
                        <span className="ms-2">
                          {typeof guest.called_note_en === "string"
                            ? guest.called_note_en
                            : ""}
                        </span>
                      </div>
                    )}
                    {width > 991 && width < 1280 ? (
                      <HoverTooltip text={getStatus(guest.status)} id={"t-7"}>
                        <div
                          className={`${
                            guest.status === 7 ? "going" : "scanned"
                          } guests-table__col guests-table__col-status`}
                        >
                          <IconOval />
                          <span className="guest-status">
                            {getStatus(guest.status)}
                          </span>
                        </div>
                      </HoverTooltip>
                    ) : (
                      <div
                        className={`${
                          guest.status === 7 ? "going" : "scanned"
                        } guests-table__col guests-table__col-status`}
                      >
                        <IconOval />
                        <span className="guest-status">
                          {getStatus(guest.status)}
                        </span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default GuestsList
