import { useTranslation } from "react-i18next"
import { useState } from "react"
import { Dropdown } from "react-bootstrap"
import dayjs from "dayjs"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  setLimit,
  getContacts,
  setCurrentPage,
  changeContact,
  setLoading,
  addContact,
  getContactsMobile,
} from "../../redux/slices/contacts/contactsSlices"
import {
  IconContactDelete,
  IconContactsChange,
  IconDeleteQuest,
  IconSort,
  IconSorting,
} from "../utils/Icons/CustomIcons"
import Paginator from "../utils/Pagination/Pagination"
import NotContacts from "../../pages/Contacts/NoContactsPage"
import EditGuest from "./EditGuest/EditGuest"
import AddGuest from "./AddGuest/AddGuest"
import ImportGuest from "./ImportGuest/ImportGuest"
import { iContactsBody } from "./types"

interface iGuest {
  first_name: string
  last_name: string
  suffix: string
  phone: string
  email: string
  deleted_at: any
}

const ContactsBody: React.FC<iContactsBody> = ({
  guests,
  deleteQuest,
  filter,
  setFilter,
  addShow,
  setAddShow,
  importShow,
  setImportShow,
  deleteChecked,
  checked,
  setChecked,
  setAllQuestsChecked,
  allQuestsChecked,
  countContacts,
  setCountContacts
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [fileImport, setFileImport] = useState<File | null>(null)
  const { current_page, total_pages, limit, contacts_uploaded } =
    useAppSelector((state) => state.contacts)

  const [newGuest, setNewGuest] = useState<iGuest>({
    first_name: "",
    last_name: "",
    suffix: "",
    phone: "",
    email: "",
    deleted_at: null,
  })

  const [changeGuest, setChangeGuest] = useState({})
  const [editShow, setEditShow] = useState(false)
  // const [countContacts, setCountContacts] = useState(limit)
  // const [allQuestsCheked, setAllQuestsChecked] = useState<number[]>([])
  // const [checked, setChecked] = useState<number[]>([])
  const [validation, setValidation] = useState([])

  const changeFilter = (sort: string, order: string) => {
    setFilter({ ...filter, sort: sort, order: order })
  }

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  const addPopupClose = () => {
    setAddShow(false)
  }

  const editPopupClose = () => {
    setValidation([])
    setEditShow(false)
  }

  const editPopupOpen = (guest: any) => {
    setChangeGuest(guest)
    setEditShow(true)
  }

  const importPopupClose = () => {
    setImportShow(false)

    if (fileImport) {
      dispatch(setLoading())
      dispatch(setCurrentPage(1))
      dispatch(getContacts(filter))
    }
  }

  const saveAddGuest = (event: any) => {
    event.preventDefault()

    dispatch(addContact(newGuest)).then((result: any) => {
      if (result.error) {
        setValidation(result.payload.errors)
      } else {
        dispatch(setLoading())
        setAddShow(false)
        dispatch(getContacts(filter))
      }
    })
  }

  const saveEditGuest = (event: any) => {
    event.preventDefault()

    dispatch(changeContact(changeGuest)).then((result: any) => {
      if (result.error) {
        setValidation(result.payload.errors)
      } else {
        dispatch(setLoading())
        setEditShow(false)
        dispatch(getContacts(filter))
      }
    })
  }

  const onDelete = () => {
    deleteChecked(checked)
    setChecked([])
    setAllQuestsChecked([])
  }

  return (
    <div className="contacts-body">
      <div className="contacts-body__controls desktop">
        <Dropdown className="dropdown-block sorting-mobile">
          {guests.length > 0 && (
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              <span className="sorting-mobile__text">
                {t("contacts.sorting.title")} <IconSorting />
              </span>
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=first_name",
                )
              }
            >
              {t("contacts.sorting.name")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=email",
                )
              }
            >
              {t("contacts.sorting.email")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=phone",
                )
              }
            >
              {t("contacts.sorting.phone")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "&orderBy=created_at",
                )
              }
            >
              {t("contacts.sorting.date")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {checked.length !== 0 && (
          <span
            className="delete-selected btn btn-simple"
            onClick={() => {
              onDelete()
            }}
          >
            <IconDeleteQuest />
            {t("buttons.deleteSelected")}
          </span>
        )}

        {contacts_uploaded.length > 0 && (
          <Dropdown className="dropdown-block show-count">
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              {t("contacts.showTitle")} {countContacts}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setCountContacts(30)
                  dispatch(setLimit(30))
                  dispatch(getContacts(filter))
                }}
              >
                {t("contacts.sorting.title")} 30
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCountContacts(60)
                  dispatch(setLimit(60))
                  dispatch(getContacts(filter))
                }}
              >
                {t("contacts.sorting.title")} 60
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {contacts_uploaded.length !== 0 ? (
        <>
          <div className="contacts-table">
            <div className="contacts-table__head">
              <div className="contacts-table__checkbox">
                <input
                  type="checkbox"
                  id="all"
                  checked={allQuestsChecked.length === limit}
                  onChange={() => {
                    if (allQuestsChecked.length > 0) {
                      setAllQuestsChecked([])
                      setChecked([])
                    } else {
                      let allQuests: any[] = []
                      guests.forEach((element) => {
                        allQuests.push(element.id)
                      })
                      setAllQuestsChecked([...allQuests])
                      setChecked([...allQuests])
                    }
                  }}
                />
                <label htmlFor="all"></label>
              </div>
              <div
                className="contacts-table__col"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "&orderBy=first_name",
                  )
                }
              >
                {t("contacts.sorting.name")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="contacts-table__col"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "&orderBy=email",
                  )
                }
              >
                {t("contacts.sorting.email")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="contacts-table__col"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "&orderBy=phone",
                  )
                }
              >
                {t("contacts.sorting.phoneNumber")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div
                className="contacts-table__col"
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "&orderBy=created_at",
                  )
                }
              >
                {t("contacts.sorting.dateCreation")}
                <span className="sort">
                  <IconSort />
                </span>
              </div>
              <div className="contacts-table__col">
                {t("contacts.sorting.actions")}
              </div>
            </div>

            <div className="contacts-table__body">
              {contacts_uploaded.map((guest) => {
                return (
                  <div className="contacts-table__row" key={guest.id}>
                    <div className="contacts-table__checkbox">
                      <input
                        type="checkbox"
                        id={`guest${guest.id}`}
                        checked={
                          (allQuestsChecked.length > 0 &&
                            allQuestsChecked.includes(guest.id)) ||
                          (checked.length > 0 && checked.includes(guest.id))
                        }
                        onChange={() => {
                          if (checked.includes(guest.id)) {
                            let newChecked = checked.filter(
                              (chk: any) => chk !== guest.id,
                            )
                            setAllQuestsChecked([])
                            setChecked([...newChecked])
                          } else {
                            setChecked([...checked, guest.id])
                          }
                        }}
                      />
                      <label htmlFor={`guest${guest.id}`}></label>
                    </div>
                    <div className="contacts-table__col">
                      <span className="name">{guest.first_name}</span>
                    </div>
                    <div className="contacts-table__col">
                      <a href={`mailto:${guest.email}`} className="mail">
                        {guest.email ? guest.email : "-"}
                      </a>
                    </div>
                    <div className="contacts-table__col">
                      <span className="phone">
                        {guest.phone ? guest.phone.split("|")[0] : "-"}
                      </span>
                    </div>
                    <div className="contacts-table__col">
                      <span className="date">
                        {dayjs(guest.created_at).format("DD/MM/YYYY HH:mm")}
                      </span>
                    </div>
                    <div className="contacts-table__col">
                      <div className="contacts-change">
                        <button
                          className="btn btn-change"
                          onClick={() => {
                            editPopupOpen(guest)
                          }}
                        >
                          <IconContactsChange />
                        </button>
                        <button
                          className="btn btn-delete"
                          onClick={() => {
                            deleteQuest(guest.id)
                          }}
                        >
                          <IconContactDelete />
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <button
            type="button"
            className="btn btnloadMore d-lg-none d-sm-flex btn btn-primary"
            onClick={() => {
              dispatch(
                setCurrentPage(
                  current_page === total_pages ? 1 : current_page + 1,
                ),
              )
              dispatch(getContactsMobile(filter))
            }}
          >
            {t("buttons.loadMore")}
          </button>

          <Paginator
            total_pages={total_pages}
            current_page={current_page}
            onPageChanged={onPageChanged}
          />
        </>
      ) : (
        <NotContacts setImportShow={setImportShow} setAddShow={setAddShow} />
      )}

      <AddGuest
        guest={newGuest}
        setGuest={setNewGuest}
        show={addShow}
        handleClose={addPopupClose}
        saveEditGuest={saveAddGuest}
        validation={validation}
      />

      <EditGuest
        guest={changeGuest}
        setGuest={setChangeGuest}
        show={editShow}
        handleClose={editPopupClose}
        saveEditGuest={saveEditGuest}
        validation={validation}
      />

      <ImportGuest
        file={fileImport}
        setFile={setFileImport}
        show={importShow}
        handleClose={importPopupClose}
      />
    </div>
  )
}

export default ContactsBody
