import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "@/redux/store"
import { IconClose } from "../../utils/Icons/CustomIcons"
import "./CopyModal.scss"

const CopyModal = ({ show, setShow }: { show: boolean; setShow: any }) => {
  const { copy_event_id } = useAppSelector((state) => state.events)
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal className={`copy-modal`} show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <div className="copy-container">
          <div className="copy">
            <div className="copy-inner">
              <div className="copy-main__header">
                <span className="copy-main__header-title">
                  {t("event.copy")}
                </span>
                <div
                  className="d-flex align-items-center"
                  onClick={() => setShow(false)}
                >
                  <IconClose className="copy-main__header-close" />
                </div>
              </div>
              <section className="copy-main">
                <div className="copy-main__form">
                  <div className="copy-main__card">
                    <p className="copy-main__card-description">
                      {t("event.copyDesc")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center copy-buttons">
                    <button
                      className="btn btn-simple"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      {t("buttons.no")}
                    </button>
                    <button
                      className="btn btn-dark"
                      onClick={() => {
                        setShow(false)
                        navigate(`/${copy_event_id}`)
                      }}
                    >
                      {t("buttons.yes")}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CopyModal
