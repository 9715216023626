import { useState } from "react"
import { Modal } from "react-bootstrap"
import PhoneInput from "react-phone-input-2"
import { t } from "i18next"
import { useAppDispatch } from "../../../redux/store"
import { testTheInvitation } from "../../../redux/slices/events/eventsSlice"
import styles from "./TestModal.module.scss"
import "react-phone-input-2/lib/style.css"

const TestModal = ({
  show,
  setShow,
  showNextStep,
  setShowNextStep,
  value,
  setValue,
  restart,
  notify,
}: any) => {
  const dispatch = useAppDispatch()
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("966")
  const changeModalHandler = () => {
    dispatch(testTheInvitation(value)).then((result) => {
      if (result.payload === "This action is unauthorized.") {
        notify(result.payload, true)
      }
    })
    setShow(!show)
    setShowNextStep(!showNextStep)
    const time = new Date()
    time.setSeconds(time.getSeconds() + 180)
    restart(time)
  }

  const onHide = () => {
    setShow(!show)
    setValue(null)
  }

  const checkInput = (e: any) => {
    e.target.value.length < 12 && setPhone(e.target.value)
    setValue(code + e.target.value)
  }

  return (
    <Modal
      className={`${styles.testModal} modal-test__invitation`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`${styles.caption}`}>{t("testModal.title")}</h2>
          <span className={styles.text}>{t("testModal.text")}</span>
          <div
            className="test-phone"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <PhoneInput
              placeholder=""
              countryCodeEditable={false}
              enableSearch={true}
              disableSearchIcon={true}
              value={code}
              onChange={(value) => setCode(value)}
            />
            <input
              type="number"
              value={phone}
              placeholder="999-99-99"
              className="phone-input-2"
              onChange={(event) => {
                checkInput(event)
              }}
            />
          </div>
          <div className={styles.controls}>
            <span
              className={`${styles.button} btn btn-simple`}
              onClick={onHide}
            >
              {t("buttons.cancel")}
            </span>
            <button
              onClick={changeModalHandler}
              className={`${styles.button} btn btn-dark`}
            >
              {t("buttons.confirm")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TestModal
