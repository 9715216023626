import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getContactsOnEvent,
  setCurrentPage,
  setLimit,
  setLoading,
} from "../../redux/slices/contacts/contactsSlices"
import { useTranslation } from "react-i18next"
import {
  IconArrowUpFat,
  IconCopyDoc,
  IconOval,
  IconSort,
  IconSorting,
} from "../utils/Icons/CustomIcons"
import HoverTooltip from "./HoverTooltip"
import Preloader from "../utils/Preloader/Preloader"

const GuestsListOpen = ({
  filter,
  setFilter,
  limit,
  checked,
  setChecked,
  allGuestsChecked,
  setAllGuestsChecked,
  notify,
  width,
}: any) => {
  const { t } = useTranslation()
  const { isLoading, data_uploaded } = useAppSelector((state) => state.contacts)
  const { event_by_hash, currentEvent } = useAppSelector(
    (state) => state.events,
  )
  const dispatch = useAppDispatch()
  const [countContacts, setCountContacts] = useState(limit)
  const [showAnswers, setShowAnswers] = useState<any>([])

  const changeFilter = (sort: string, order: string) => {
    setFilter({ ...filter, sort: sort, order: order })
  }

  useEffect(() => {
    dispatch(setLoading())
    dispatch(setCurrentPage(1))
    dispatch(getContactsOnEvent(filter))
    checked
  }, [filter.sort, filter.order, filter.searchValue, filter.statusNumber])

  const getStatus = (statusNumber: any) => {
    switch (statusNumber) {
      case 0:
        return t("event.stats.waiting")
        break
      case 1:
        return t("event.stats.going")
        break
      case 2:
        return t("event.stats.decline")
        break
      case 4:
        return t("event.stats.scanned")
        break
      case 5:
        return t("event.stats.visited")
        break
      case 6:
        return t("event.stats.failed")
        break
      case 7:
        return t("event.stats.notInvitedYet")
        break
      default:
        return ""
        break
    }
  }

  return (
    <div className="guests-body__wrapper">
      <div className="guests-body guests-body__open">
        <div className={`d-flex event-guests__link`}>
          <p className={`event-guests__link-title`}>
            {t("event.invitationLink")}
          </p>
          <div className={`event-guests__link-container`}>
            <a
              target="_blank"
              href={`/register/${currentEvent && currentEvent.open_event_hash}`}
              className={`event-guests__link-link`}
            >
              app.mazoom.sa/register/{currentEvent && currentEvent.hash}
            </a>
            <div
              className={`event-guests__link-img`}
              onClick={() =>
                navigator.clipboard.writeText(
                  `app.mazoom.sa/register/${currentEvent && currentEvent.hash}`,
                )
              }
            >
              <IconCopyDoc />
            </div>
          </div>
        </div>
        <div
          className={`guests-body__controls ${
            checked.length === 0 && "controls-visible"
          }`}
        >
          <Dropdown className="dropdown-block sorting-mobile">
            <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
              <span className="sorting-mobile__text">
                {t("guests.sorting.title")} <IconSorting />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=first_name",
                  )
                }
              >
                {t("guests.sorting.name")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=email",
                  )
                }
              >
                {t("guests.sorting.email")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=phone",
                  )
                }
              >
                {t("guests.sorting.phoneNumber")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=quantity_members",
                  )
                }
              >
                {t("guests.sorting.invites")}
              </Dropdown.Item>
              <Dropdown.Item>{t("guests.sorting.calledNotes")}</Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  changeFilter(
                    filter.sort === "&sortedBy=asc"
                      ? "&sortedBy=desc"
                      : "&sortedBy=asc",
                    "orderBy=status",
                  )
                }
              >
                {t("guests.sorting.status")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {
            <Dropdown className="dropdown-block show-count">
              <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent">
                {t("contacts.showTitle")}
                {` ${countContacts}`}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(30)
                    dispatch(setLimit(30))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 30
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setCountContacts(60)
                    dispatch(setLimit(60))
                    dispatch(getContactsOnEvent(filter))
                  }}
                >
                  {t("contacts.sorting.title")} 60
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </div>
      </div>
      <>
        <div
          className="guests-table"
          style={
            event_by_hash.questions.data.length < 3
              ? { overflowX: "visible", width: "calc(100% - 48px)" }
              : { overflowX: "scroll" }
          }
        >
          <div
            className="guests-table__head"
            style={
              event_by_hash.questions.data.length < 3
                ? { justifyContent: "space-between" }
                : { justifyContent: "flex-start" }
            }
          >
            <div className="guests-table__checkbox">
              <input
                type="checkbox"
                id="all"
                checked={allGuestsChecked.length === limit}
                onChange={() => {
                  if (allGuestsChecked.length > 0) {
                    setAllGuestsChecked([])
                    setChecked([])
                  } else {
                    let allGuests: any[] = []
                    data_uploaded.forEach((element) => {
                      allGuests.push(element.id)
                    })
                    setAllGuestsChecked([...allGuests])
                    setChecked([...allGuests])
                  }
                }}
              />
              <label htmlFor="all"></label>
            </div>
            <div
              className="guests-table__col guests-table__col-name"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=first_name",
                )
              }
            >
              {t("contacts.sorting.name")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-email"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=email",
                )
              }
            >
              {t("contacts.sorting.email")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-phone"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=phone",
                )
              }
            >
              {window.innerWidth > 1279 ? (
                <>{t("contacts.sorting.phoneNumber")}</>
              ) : (
                <div className="text">{t("contacts.sorting.phoneNumber")}</div>
              )}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-invites"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=quantity_members",
                )
              }
            >
              <div className="text">{t("contacts.sorting.invites")}</div>
              <span className="sort">
                <IconSort />
              </span>
            </div>
            <div
              className="guests-table__col guests-table__col-status"
              onClick={() =>
                changeFilter(
                  filter.sort === "&sortedBy=asc"
                    ? "&sortedBy=desc"
                    : "&sortedBy=asc",
                  "orderBy=status",
                )
              }
            >
              {t("contacts.sorting.status")}
              <span className="sort">
                <IconSort />
              </span>
            </div>
            {event_by_hash.id !== 0 &&
              event_by_hash.questions.data.length > 0 &&
              event_by_hash.questions.data.map((item: any) => {
                return (
                  <HoverTooltip
                    text={`${item.question_text}`}
                    id={"t-2"}
                    key={item.id}
                  >
                    <div
                      className="guests-table__col guests-table__col-notes"
                      key={item.id}
                    >
                      <div className="text">{item.question_text}</div>
                    </div>
                  </HoverTooltip>
                )
              })}
          </div>
          {isLoading ? (
            <Preloader />
          ) : (
            <div
              className="guests-table__body"
              style={{
                width:
                  width > 991 && event_by_hash.questions.data.length > 3
                    ? "fit-content"
                    : "",
              }}
            >
              {data_uploaded &&
                data_uploaded.map((guest) => {
                  return (
                    <div
                      className="guests-table__row"
                      key={guest.id}
                      style={
                        event_by_hash.questions.data.length < 3
                          ? { justifyContent: "space-between" }
                          : { justifyContent: "flex-start" }
                      }
                    >
                      <div className="guests-table__checkbox">
                        <input
                          type="checkbox"
                          id={`guest${guest.id}`}
                          checked={
                            allGuestsChecked.includes(guest.id) ||
                            checked.includes(guest.id)
                          }
                          onChange={() => {
                            if (checked.includes(guest.id)) {
                              let newChecked = checked.filter(
                                (chk: any) => chk !== guest.id,
                              )
                              setAllGuestsChecked([])
                              setChecked([...newChecked])
                            } else {
                              setChecked([...checked, guest.id])
                            }
                          }}
                        />
                        <label htmlFor={`guest${guest.id}`}></label>
                      </div>
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip text={`${guest.first_name}`} id={"t-2"}>
                          <div className="guests-table__col guests-table__col-name">
                            <span className="name">{guest.first_name}</span>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div className="guests-table__col guests-table__col-name">
                          <span className="name">{guest.first_name}</span>
                        </div>
                      )}
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip text={guest.email} id={"t-3"}>
                          <div className="guests-table__col guests-table__col-email">
                            <a href={`mailto:${guest.email}`} className="mail">
                              {guest.email ? guest.email : "-"}
                            </a>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div className="guests-table__col guests-table__col-email">
                          <a href={`mailto:${guest.email}`} className="mail">
                            {guest.email ? guest.email : "-"}
                          </a>
                        </div>
                      )}
                      <div className="guests-table__col guests-table__col-phone">
                        <span className="phone">
                          {guest.phone ? guest.phone.split("|")[0] : "-"}
                        </span>
                      </div>

                      <div className="guests-table__col guests-table__col-invites">
                        <span className="invites">
                          {window.screen.width < 992 && "Invites actual/max: "}
                          {guest.guests_number_actual !== null
                            ? guest.guests_number_actual
                            : "-"}
                          /
                          {guest.guests_number_max !== null &&
                          guest.guests_number_max !== undefined
                            ? guest.guests_number_max - 1
                            : "-"}
                        </span>
                      </div>
                      {width > 991 && width < 1280 ? (
                        <HoverTooltip text={getStatus(guest.status)} id={"t-7"}>
                          <div
                            className={`${
                              guest.status === 7 ? "going" : "scanned"
                            } guests-table__col guests-table__col-status`}
                          >
                            <IconOval />
                            <span className="guest-status">
                              {getStatus(guest.status)}
                            </span>
                          </div>
                        </HoverTooltip>
                      ) : (
                        <div
                          className={`${
                            guest.status === 7 ? "going" : "scanned"
                          } guests-table__col guests-table__col-status`}
                        >
                          <IconOval />
                          <span className="guest-status">
                            {getStatus(guest.status)}
                          </span>
                        </div>
                      )}
                      {window.innerWidth < 992 &&
                        event_by_hash.id !== 0 &&
                        event_by_hash.questions.data.length > 0 && (
                          <div className="called__notes guests-table__col guests-table__col-title">
                            {t("event.answers")}
                            <span
                              className={
                                showAnswers.includes(guest.id) ? "arrow" : ""
                              }
                              onClick={() =>
                                setShowAnswers(
                                  showAnswers.includes(guest.id)
                                    ? showAnswers.filter(
                                        (item: any) => item.id === guest.id,
                                      )
                                    : [...showAnswers, guest.id],
                                )
                              }
                            >
                              <IconArrowUpFat />
                            </span>
                          </div>
                        )}
                      {guest.status === 1
                        ? guest.answers.data.map((item: any, index: number) => {
                            return (
                              <div
                                className={
                                  "called__notes guests-table__col guests-table__col-notes"
                                }
                                key={index}
                                style={{
                                  display: showAnswers.includes(guest.id)
                                    ? "none"
                                    : "flex",
                                }}
                              >
                                {window.innerWidth < 992 && (
                                  <div className="guests-table__col-desc">
                                    {item.question}
                                  </div>
                                )}
                                <HoverTooltip text={item.answer} id={"t-7"}>
                                  <span className="guests-table__col-answ">
                                    {item.answer}
                                  </span>
                                </HoverTooltip>
                              </div>
                            )
                          })
                        : event_by_hash.id !== 0 &&
                          event_by_hash.questions.data.length > 0 &&
                          event_by_hash.questions.data.map(
                            (item: any, index) => {
                              return (
                                <div
                                  className={
                                    "called__notes guests-table__col guests-table__col-notes"
                                  }
                                  key={index}
                                >
                                  {window.innerWidth < 992 && (
                                    <div className="guests-table__col-desc">
                                      {item.question}
                                    </div>
                                  )}
                                  <span className="guests-table__col-answ">
                                    -
                                  </span>
                                </div>
                              )
                            },
                          )}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </>
    </div>
  )
}

export default GuestsListOpen
