export default function ColorPeekIcon({ color = "black" }: { color: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_595_4370)">
        <path
          d="M17.75 6.99994L14 3.24994L4 13.2499V16.9999H7.75L17.75 6.99994ZM20.705 4.04494C21.095 3.65494 21.095 3.01994 20.705 2.62994L18.37 0.294941C17.98 -0.0950586 17.345 -0.0950586 16.955 0.294941L15 2.24994L18.75 5.99994L20.705 4.04494Z"
          fill="black"
        />
        <path
          d="M0 20H24V24H0V20Z"
          fill={color}
          fillOpacity={color === "black" ? 0.36 : 1}
        />
      </g>
      <defs>
        <clipPath id="clip0_595_4370">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
