import Select from "react-select"
import { useTranslation } from "react-i18next"

const SelectTypeEvent = ({
  placeholder,
  event,
  setEvent,
  error,
  value,
  handleChange,
}: any) => {
  const { t } = useTranslation()
  const eventType = [
    { value: "WEDDING", label: t("selectTypeEvent.wedding"), id: 0 },
    { value: "ENGAGEMENT", label: t("selectTypeEvent.engagement"), id: 1 },
    { value: "GRADUATION", label: t("selectTypeEvent.graduation"), id: 2 },
    { value: "CORPORATE_EVENT", label: t("selectTypeEvent.corporate"), id: 3 },
    { value: "OPENING", label: t("selectTypeEvent.opening"), id: 4 },
    { value: "BIRTHDAY", label: t("selectTypeEvent.birthday"), id: 5 },
    { value: "NEW_BORN", label: t("selectTypeEvent.newBorn"), id: 6 },
    { value: "NEW_HOUSE", label: t("selectTypeEvent.newHouse"), id: 7 },
    {
      value: "FAMILY_OR_FRIENDS_GATHERING",
      label: t("selectTypeEvent.family"),
      id: 8,
    },
    {
      value: "NATIONAL_HOLIDAY_PARTY",
      label: t("selectTypeEvent.national"),
      id: 9,
    },
    { value: "REUNION", label: t("selectTypeEvent.reunion"), id: 10 },
    { value: "OTHER", label: t("selectTypeEvent.other"), id: 11 },
  ]

  return (
    <div className={error ? "select-event input-error" : "select-event"}>
      <Select
        // menuIsOpen={true}
        classNamePrefix="select-event"
        placeholder={""}
        options={eventType}
        value={eventType.find((e) => e.value === value)}
        onChange={(val: any) => {
          handleChange(val.value)
          setEvent({ ...event, type: val.id })
        }}
      />

      <label className={event.type >= 0 ? "active" : ""} htmlFor="">
        {placeholder}
      </label>

      {error && <span className="input-item__error error mt-1">{error}</span>}
    </div>
  )
}

export default SelectTypeEvent
