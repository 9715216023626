import { useRef, useState } from "react"
import Select from "react-select"

type OptionType = {
  value: string
  label: string | JSX.Element
}
type SelectProps = {
  options: OptionType[]
  defaultValue?: string
  width?: string
  currentValue: any
  setCurrentValue: any
  showOthers: any
  controlWidth: string
  hide: { font: boolean; align: boolean; color: boolean; text: boolean }
  setHide: any
  type: string
}

const QrDesignSelect = ({
  options,
  width,
  currentValue,
  setCurrentValue,
  showOthers,
  controlWidth,
  hide,
  setHide,
  type,
}: SelectProps) => {
  return (

    <Select
      defaultValue={options[0]}
      options={options}
      menuIsOpen={type === "font" ? hide.font : hide.align}
      value={currentValue}
      onMenuOpen={() => setHide(
        type === "font"
          ? { font: true, align: false, color: false, text: false }
          : { font: false, align: true, color: false, text: false },
      )}
      onMenuClose={() => setHide(
        type === "font"
          ? { font: false, align: false, color: false, text: false }
          : { font: false, align: false, color: false, text: false },
      )}
      onChange={(e) => {
        setCurrentValue(e)
      }}
      isSearchable={false}
      styles={{
        container: (state) => ({
          display: showOthers ? "flex" : "none",
          maxWidth: "120px",
          height: window.innerWidth < 992 ? '16px' : '24px',
        }),
        control: (state) => ({
          display: "flex",
          fontSize: window.innerWidth > 991 ? 14 : 12,
          padding: "0px 0px",
          border: 0,
          zIndex: 100,
        }),
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        option: (defaultStyles, state) => ({
          display: "flex",
          alignItems: "center",
          padding: 10,
          borderBottom: "1px solid #F2F4F7",
          cursor: "pointer",
          fontSize: 14,
          zIndex: 100,
          backgroundColor: state.isSelected ? "#F2F4F7" : "#FFF",
        }),
        menuList: (state) => ({
          padding: 0,
          zIndex: 100,
          maxWidth: width,
          width: width,
          position: "absolute",
        }),
        menu: (state) => ({
          maxWidth: width,
          position: "absolute",
          zIndex: 100,
          top: "36px",
        }),
        valueContainer: (state) => ({
          ...state,
          padding: "0px 0px",
          width: controlWidth,
          height: window.innerWidth < 992 ? '16px' : '24px'
        }),
        indicatorsContainer: (state) => ({
          ...state,
          padding: "0px",
        }),
        dropdownIndicator: (state) => ({
          ...state,
          padding: "0px",
          width: '16px',
        }),
      }}
    />
  )
}

export default QrDesignSelect
