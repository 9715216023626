import { ChangeEvent, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import Switch from "react-switch"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import {
  createEventQuestions,
  createQuestionOptions,
  deleteQuestionOptions,
  sendOpenSettings,
  updateEventQuestions,
  updateQuestionOptions,
  clearEventId,
} from "@/redux/slices/events/eventsSlice"
import {
  IconCheckSmall,
  IconChevronUp,
  IconEdit,
  IconPlus,
  IconSortArrows,
  IconTrash,
} from "@/components/utils/Icons/CustomIcons"
import TypeSelect from "../TypeSelect"
import styles from "./OpenModal.module.scss"
import HoverTooltip from "@/components/Event/HoverTooltip"

type Toggle = {
  name: boolean
  phone: boolean
  email: boolean
  number: boolean
}

type Dropdown = {
  manage: boolean
  about: boolean
  questions: boolean
}

type OpenModal = {
  show: boolean
  setShow: (arg0: boolean) => void
  setValues: any
  values: any
  setId: (arg0: number) => void
  setShowDelete: (arg0: boolean) => void
  setShowRegister: (arg0: boolean) => void
}

type Item = {
  id: number
  order: number
  question: string
  type: number | string
  min: number
  max: number
  multiple: boolean
  options: { option: any; order: number }[] | []
  dropdown: []
}

type Value = {
  edit: boolean
  id: number
  order: number
  dropdown: any[] | []
}

type Option = { id: number; order: number; option: string }

const notify = (text: string, color: boolean) => {
  color
    ? toast.error(text, {
        position: "top-right",
        autoClose: 3000,
      })
    : toast.success(text, {
        position: "top-right",
        autoClose: 3000,
      })
}

const OpenModal = ({
  show,
  setShow,
  setValues,
  values,
  setId,
  setShowDelete,
  setShowRegister,
}: OpenModal) => {
  const [toggle, setToggle] = useState<Toggle>({
    name: false,
    phone: false,
    email: false,
    number: false,
  })
  const [dropdown, setDropdown] = useState<Dropdown>({
    manage: false,
    about: false,
    questions: false,
  })
  const [number, setNumber] = useState<any>(0)
  const { event_id } = useParams()
  const [draggedItem, setDraggedItem] = useState<Item | null>(null)
  const dragCardRef = useRef<HTMLDivElement | null>(null)
  const { new_event_id } = useAppSelector((state) => state.events)
  const { is_premium } = useAppSelector((state) => state.auth)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onHide = () => {
    setShow(!show)
  }

  const type = ["Text", "Dropdown options", "Number", "URL"]

  const addQuestion = () => {
    setValues([
      ...values,
      {
        id: values.length + 1,
        question: "",
        type: "Number",
        dropdown: [
          { id: 0, order: 1, option: "0" },
          { id: 1, order: 2, option: "0" },
        ],
        multiple: true,
        min: 0,
        max: 9,
        edit: true,
        order: 0,
      },
    ])
  }

  const saveQuestion = (item: Item, index: number) => {
    if (item.order === 0) {
      if (values.length > 10) {
        notify(t("notify.only10"), true)
      } else {
        if (item.min > item.max) {
          notify(t("notify.check"), true)
        } else {
          dispatch(
            createEventQuestions({
              order: index + 1,
              question: item.question,
              type: type.findIndex((pos) => pos === item.type),
              min: item.min,
              max: item.max,
              multiple:
                item.type === "Dropdown options" ? item.multiple : false,
              options:
                item.type === "Dropdown options"
                  ? item.dropdown.map((drop: any, index: number) => {
                      return {
                        option: drop.option,
                        order: index,
                      }
                    })
                  : item.type === "Text"
                  ? [
                      {
                        option: "cccd1",
                        order: 1,
                      },
                    ]
                  : [],
            }),
          ).then((res) => {
            if (res.payload.errors) {
              if (Object.keys(res.payload?.errors)[0].includes("option")) {
                notify(t("notify.optionReq"), true)
              } else {
                notify(
                  (Object.values(res.payload.errors) as string[][])[0][0],
                  true,
                )
              }
            } else {
              if (values.length > 0) {
                if (res.payload.success === false) {
                  notify(t("notify.exists"), true)
                } else {
                  setValues(
                    values.map((value: Value) => {
                      return value.id === item.id
                        ? {
                            ...value,
                            edit: false,
                            id: res.payload.data.id,
                            order: index + 1,
                            dropdown:
                              item.type === "Dropdown options"
                                ? res.payload.data.options.data
                                : item.dropdown,
                          }
                        : value
                    }),
                  )
                }
              }
            }
          })
        }
      }
    } else {
      if (item.dropdown.find((item: any) => item.option === "")) {
        notify(t("notify.optionReq"), true)
      } else {
        if (item.min > item.max) {
          notify(t("notify.check"), true)
        } else {
          dispatch(
            updateEventQuestions({
              id: item.id,
              order: item.order,
              question: item.question,
              type: type.findIndex((pos) => pos === item.type),
              min: item.min,
              max: item.max,
              multiple:
                item.type === "Dropdown options" ? item.multiple : false,
              options:
                item.type === "Dropdown options"
                  ? []
                  : item.type === "Text"
                  ? [
                      {
                        option: "cccd1",
                        order: 1,
                      },
                    ]
                  : [],
            }),
          ).then((res) => {
            if (res.payload?.errors) {
              if (Object.keys(res.payload?.errors)[0].includes("option")) {
                notify(t("notify.optionReq"), true)
              } else {
                notify(
                  (Object.values(res.payload.errors) as string[][])[0][0],
                  true,
                )
              }
            } else {
              if (values.length > 0) {
                if (res.payload.success === false) {
                  notify(t("notify.exists"), true)
                } else {
                  setValues(
                    values.map((value: Value) => {
                      return value.id === item.id
                        ? { ...value, edit: false }
                        : value
                    }),
                  )
                  if (item.type === "Dropdown options") {
                    item.dropdown.forEach((item1: Option) => {
                      const found = res.payload.data.options.data.find(
                        (item2: any) => item1.id === item2.id,
                      )
                      if (found) {
                        dispatch(updateQuestionOptions(item1))
                      } else {
                        dispatch(
                          createQuestionOptions({
                            id: res.payload.data.id,
                            option: item1.option,
                            order: item1.order,
                          }),
                        ).then((res) => {
                          setValues(
                            values.map((value: Value) => {
                              return value.id === item.id
                                ? {
                                    ...value,
                                    edit: false,
                                    dropdown: item.dropdown.map(
                                      (dropdownItem: Option) =>
                                        dropdownItem.order ===
                                        res.payload.data.order
                                          ? {
                                              ...dropdownItem,
                                              id: res.payload.data.id,
                                            }
                                          : dropdownItem,
                                    ),
                                  }
                                : { ...value, edit: false }
                            }),
                          )
                        })
                      }
                    })
                    res.payload.data.options.data.forEach((item1: Option) => {
                      const found2 = item.dropdown.find(
                        (item2: Option) => item1.order === item2.order,
                      )
                      if (found2) {
                      } else {
                        dispatch(deleteQuestionOptions(item1.id))
                      }
                    })
                  }
                }
              }
            }
          })
        }
      }
    }
  }

  const editQuestion = (item: Item) => {
    setValues(
      values.map((value: Value) => {
        return value.id === item.id ? { ...value, edit: true } : value
      }),
    )
  }

  const saveModal = () => {
    console.log(values)
    if (
      values.length <= 10 &&
      values.find((item: any) => item.question === "") === undefined &&
      values.find((item: any) => item.question.length < 5) === undefined &&
      values.find((item: any) => item.min > item.max) === undefined
    ) {
      dispatch(
        sendOpenSettings({
          event_id: new_event_id,
          open_event_email_required: toggle.email ? 1 : 0,
          open_event_number_of_guests_required: toggle.number ? 1 : 0,
          open_event_number_of_guests_max: number,
        }),
      ).then(() => {
        setShow(false)
        setShowRegister(true)
        setTimeout(() => {
          dispatch(clearEventId())
          setValues([])
        }, 3000)
      })
    }
    values.map((newItem: any, index: any) => {
      saveQuestion(newItem, index)
    })
  }

  const handleInputChange = (
    index: number,
    value: string,
    inputIndex: number,
  ) => {
    setValues((prevValues: Value[]) => {
      const newValues = [...prevValues]
      newValues[index] = {
        ...newValues[index],
        dropdown: newValues[index].dropdown.map((item: Option, i: number) =>
          i === inputIndex ? { ...item, ["option"]: value } : item,
        ),
      }
      return newValues
    })
  }

  const handleAddInput = (item: Item, index: number, value: string) => {
    if (item.multiple === true) {
      setValues((prevValues: Value[]) => {
        const newValues = [...prevValues]
        const newDropdown = [
          ...newValues[index].dropdown,
          {
            id: newValues[index].dropdown.length,
            order: newValues[index].dropdown.length + 1,
            option: value,
          },
        ]
        newValues[index] = {
          ...newValues[index],
          dropdown: newDropdown,
        }
        return newValues
      })
    }
  }

  const handleDeleteInput = (index: number, inputIndex: number) => {
    const updatedA = [...values]
    updatedA[index].dropdown.splice(inputIndex, 1)
    setValues(updatedA)
  }

  const handleSetMultiple = (index: number, value: boolean) => {
    const updatedA = [...values]
    updatedA[index].multiple = value
    if (value === false) {
      updatedA[index].dropdown.splice(2, updatedA[index].dropdown.length - 1)
    }
    setValues(updatedA)
  }

  const handleDeleteQuestion = (item: Item) => {
    setId(item.id)
    setShowDelete(true)
    setShow(false)
  }

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    const closestElement = (e.currentTarget as HTMLDivElement).querySelector(
      ".drag-button",
    )
    const left = closestElement?.getBoundingClientRect().left
    const right = closestElement?.getBoundingClientRect().right
    const top = closestElement?.getBoundingClientRect().top
    const bottom = closestElement?.getBoundingClientRect().bottom
    if (
      left &&
      right &&
      top &&
      bottom &&
      e.clientX > left &&
      e.clientX < right &&
      e.clientY > top &&
      e.clientY < bottom
    ) {
      setDraggedItem(item)
    } else {
      e.preventDefault()
    }
  }

  const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggedItem(null)
    e.currentTarget.style.opacity = "1"
  }

  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    e.preventDefault()
    if (!draggedItem) return

    const newList = [...values]
    const draggedIndex = newList.findIndex((i) => i.id === draggedItem.id)
    const targetIndex = newList.findIndex((i) => i.id === item.id)

    newList.splice(draggedIndex, 1)
    newList.splice(targetIndex, 0, draggedItem)

    const arr = newList.map((newItem: any, index: number) => {
      return { ...newItem, order: index + 1 }
    })

    setValues(arr)
  }

  return (
    <Modal
      className={`${styles.testModal} open-modal`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`${styles.caption}`}>{t("openModal.title")}</h2>
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <h4 className={`${styles.sectionTitle}`}>
                  {t("openModal.manage")}
                </h4>
                <button
                  className={`${styles.sectionDropdown}`}
                  onClick={() =>
                    setDropdown({ ...dropdown, manage: !dropdown.manage })
                  }
                >
                  <IconChevronUp />
                </button>
              </div>
              <p
                className={`${styles.title} ${styles.titleFirst}`}
                style={{
                  display: dropdown.manage === false ? "none" : "block",
                }}
              >
                {t("openModal.fields")}
              </p>
              <div
                className={`${styles.switchPoint} switch`}
                style={{ display: dropdown.manage === false ? "none" : "flex" }}
              >
                <label
                  htmlFor="material-switch"
                  className={`${styles.switcherLabel}`}
                >
                  <Switch
                    onChange={() =>
                      setToggle({ ...toggle, name: !toggle.name })
                    }
                    checked={true}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px"
                    activeBoxShadow="0px"
                    width={60}
                    height={28}
                    handleDiameter={24}
                    className={`${
                      styles.switcherButton
                    } switcher-name switched ${
                      toggle.name ? `${styles.switcherButtonSwitched}` : ""
                    }`}
                  />
                  <h5 className={`${styles.switcherCheck}`}>
                    {t("openModal.first")}
                  </h5>
                </label>
              </div>
              <div
                className={`${styles.switchPoint} switch`}
                style={{ display: dropdown.manage === false ? "none" : "flex" }}
              >
                <label
                  htmlFor="material-switch"
                  className={`${styles.switcherLabel}`}
                >
                  <Switch
                    onChange={() =>
                      setToggle({ ...toggle, phone: !toggle.phone })
                    }
                    checked={true}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px"
                    activeBoxShadow="0px"
                    width={60}
                    height={28}
                    handleDiameter={24}
                    className={`${
                      styles.switcherButton
                    } switcher-phone switched ${
                      toggle.phone ? `${styles.switcherButtonSwitched}` : ""
                    }`}
                  />
                  <h5 className={`${styles.switcherCheck}`}>
                    {t("openModal.phone")}
                  </h5>
                </label>
              </div>
              <div
                className={`${styles.switchPoint} switch`}
                style={{ display: dropdown.manage === false ? "none" : "flex" }}
              >
                <label
                  htmlFor="material-switch"
                  className={`${styles.switcherLabel}`}
                >
                  <Switch
                    onChange={() =>
                      setToggle({ ...toggle, email: !toggle.email })
                    }
                    checked={toggle.email}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px"
                    activeBoxShadow="0px"
                    width={60}
                    height={28}
                    handleDiameter={24}
                    className={`${styles.switcherButton} switcher-email ${
                      toggle.email ? `switched` : ""
                    } ${
                      toggle.email ? `${styles.switcherButtonSwitched}` : ""
                    }`}
                  />
                  <h5 className={`${styles.switcherCheck}`}>
                    {t("openModal.email")}
                  </h5>
                </label>
              </div>
              <div
                className={`${styles.switchPoint} switch`}
                style={{ display: dropdown.manage === false ? "none" : "flex" }}
              >
                <label
                  htmlFor="material-switch"
                  className={`${styles.switcherLabel}`}
                >
                  <Switch
                    onChange={() =>
                      setToggle({ ...toggle, number: !toggle.number })
                    }
                    checked={toggle.number}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px"
                    activeBoxShadow="0px"
                    width={60}
                    height={28}
                    handleDiameter={24}
                    className={`${styles.switcherButton} switcher-number ${
                      toggle.number ? `switched` : ""
                    } ${
                      toggle.number ? `${styles.switcherButtonSwitched}` : ""
                    }`}
                  />
                  <h5 className={`${styles.switcherCheck}`}>
                    {t("openModal.guests")}
                  </h5>
                </label>
                <div className={`${styles.inputContainer}`}>
                  <label className={`${styles.inputLabel}`}>
                    {t("openModal.maxGuests")}
                  </label>
                  <input
                    className={`${styles.input}`}
                    disabled={!toggle.number}
                    type="number"
                    value={toggle.number ? number : ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setNumber(e.target.value)
                    }
                  ></input>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <h4 className={`${styles.sectionTitle}`}>
                  {t("openModal.about")}
                </h4>
                <button
                  className={`${styles.sectionDropdown}`}
                  onClick={() =>
                    setDropdown({ ...dropdown, about: !dropdown.about })
                  }
                >
                  <IconChevronUp />
                </button>
              </div>
              <ul
                style={{ display: dropdown.about === false ? "none" : "block" }}
              >
                <p className={styles.title}>{t("openModal.free")}</p>
                <li className={styles.element}>
                  {t("openModal.registrations")}
                </li>
                <li className={styles.element}>{t("openModal.oneQuestion")}</li>
              </ul>
              <ul
                style={{ display: dropdown.about === false ? "none" : "block" }}
              >
                <p className={styles.title}>{t("openModal.premium")}</p>
                <li className={styles.element}>
                  {t("openModal.registrations10000")}
                </li>
                <li className={styles.element}>{t("openModal.questions")}</li>
              </ul>
            </div>
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <h4 className={`${styles.sectionTitle}`}>
                  {t("openModal.questionsTitle")}
                </h4>
                <button
                  className={`${styles.sectionDropdown}`}
                  onClick={() =>
                    setDropdown({ ...dropdown, questions: !dropdown.questions })
                  }
                >
                  <IconChevronUp />
                </button>
              </div>
              {values &&
                values.map((item: any, index: number) => {
                  return item.edit === true ? (
                    <div
                      draggable={true}
                      onDragStart={(e) => dragStartHandler(e, item)}
                      onDragEnd={(e) => dragEndHandler(e)}
                      onDragOver={(e) => dragOverHandler(e)}
                      onDrop={(e) => dropHandler(e, item)}
                      ref={dragCardRef}
                      key={index}
                      style={{
                        display:
                          dropdown.questions === false ? "none" : "block",
                      }}
                    >
                      <div className={`${styles.questionTitle}`}>
                        <span className={styles.questionNumber}>
                          {index + 1}.
                        </span>
                        <div className={styles.questionIcons}>
                          <div className={`${styles.sectionIcon} drag-button`}>
                            <IconSortArrows />
                          </div>
                          <div
                            className={styles.sectionIcon}
                            onClick={() => handleDeleteQuestion(item)}
                          >
                            <IconTrash />
                          </div>
                          <div
                            className={styles.sectionIcon}
                            onClick={() => saveQuestion(item, index)}
                          >
                            <IconCheckSmall />
                          </div>
                        </div>
                      </div>
                      <div className={styles.sectionSelect}>
                        <input
                          className={styles.sectionSelectInput}
                          placeholder={t("placeholder.question")}
                          value={item.question}
                          onChange={(e) =>
                            setValues(
                              values.map((value: any) => {
                                return value.id === item.id
                                  ? { ...value, question: e.target.value }
                                  : value
                              }),
                            )
                          }
                        />
                        <p className={styles.sectionLabel}>
                          {t("labels.question")}
                        </p>
                      </div>
                      <div>
                        <TypeSelect
                          values={values}
                          value={item.type}
                          setValues={setValues}
                          item={item}
                        />
                        <p className={styles.sectionLabel}>
                          {t("labels.response")}
                        </p>
                      </div>
                      {item.type === "Number" && (
                        <div className={`${styles.inputsContainer}`}>
                          <div className={`${styles.questionInput}`}>
                            <input
                              className={`${styles.questionInputInput}`}
                              type="number"
                              placeholder="0"
                              value={item.min}
                              onChange={(e) =>
                                setValues(
                                  values.map((value: any) => {
                                    return value.id === item.id
                                      ? { ...value, min: e.target.value }
                                      : value
                                  }),
                                )
                              }
                            />
                            <p
                              className={`${styles.sectionLabel} ${styles.sectionLabelMinMax}`}
                            >
                              {t("labels.min")}
                            </p>
                          </div>
                          <div className={`${styles.questionInput}`}>
                            <input
                              className={`${styles.questionInputInput}`}
                              type="number"
                              placeholder="9"
                              value={item.max}
                              onChange={(e) =>
                                setValues(
                                  values.map((value: any) => {
                                    return value.id === item.id
                                      ? { ...value, max: e.target.value }
                                      : value
                                  }),
                                )
                              }
                            />
                            <p
                              className={`${styles.sectionLabel} ${styles.sectionLabelMinMax}`}
                            >
                              {t("labels.max")}
                            </p>
                          </div>
                        </div>
                      )}
                      {item.type === "Dropdown options" && (
                        <div
                          className={`${styles.switchPoint} ${styles.switchPointMultiple} switch`}
                        >
                          <label
                            htmlFor="material-switch"
                            className={`${styles.switcherLabel}`}
                          >
                            <Switch
                              onChange={() =>
                                handleSetMultiple(index, !item.multiple)
                              }
                              checked={item.multiple}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px"
                              disabled={is_premium === 0 ? true : false}
                              activeBoxShadow="0px"
                              width={60}
                              height={28}
                              handleDiameter={24}
                              className={`${
                                styles.switcherButton
                              } switcher-multiple ${
                                item.multiple ? `switched` : ""
                              } ${
                                item.multiple
                                  ? `${styles.switcherButtonSwitched}`
                                  : ""
                              } ${is_premium === 0 ? "disabled" : ""}`}
                            />
                            <h5
                              className={`${styles.switcherCheck} ${styles.switcherCheckMultiple}`}
                            >
                              {t("labels.multiple")}
                            </h5>
                          </label>
                          <button
                            className={`btn btn-simple ${styles.btnAddOptions}`}
                            onClick={() => handleAddInput(item, index, "")}
                          >
                            <IconPlus />
                            {window.screen.width > 768
                              ? t("buttons.addOption")
                              : ""}
                          </button>
                        </div>
                      )}
                      {item.type === "Dropdown options" && (
                        <div className={`${styles.inputsContainer}`}>
                          {item.dropdown.map(
                            (dropdownItem: any, dropdownIndex: number) => {
                              return (
                                <div
                                  className={`${styles.questionInput}`}
                                  key={dropdownIndex}
                                >
                                  <input
                                    className={`${styles.questionInputInput}`}
                                    type="text"
                                    placeholder="0"
                                    value={dropdownItem.option}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        e.target.value,
                                        dropdownIndex,
                                      )
                                    }
                                  ></input>
                                  <button
                                    style={{
                                      display:
                                        dropdownIndex > 1 ? "flex" : "none",
                                    }}
                                    className={`${styles.questionInputButton}`}
                                    onClick={() =>
                                      handleDeleteInput(index, dropdownIndex)
                                    }
                                  >
                                    <IconTrash />
                                  </button>
                                  <p
                                    className={`${styles.sectionLabel} ${styles.sectionLabelMinMax}`}
                                  >
                                    {dropdownIndex + 1}
                                    {t("labels.option")}
                                  </p>
                                </div>
                              )
                            },
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      draggable={true}
                      onDragStart={(e) => dragStartHandler(e, item)}
                      onDragEnd={(e) => dragEndHandler(e)}
                      onDragOver={(e) => dragOverHandler(e)}
                      onDrop={(e) => dropHandler(e, item)}
                      ref={dragCardRef}
                      key={index}
                      style={{
                        display: dropdown.questions === false ? "none" : "flex",
                      }}
                    >
                      <div
                        className={`${styles.questionTitle} ${styles.questionTitleSave} ${styles.questionTitleMobile}`}
                      >
                        {window.screen.width > 767 ? (
                          <div className={`${styles.questionLeft}`}>
                            <span className={styles.questionNumber}>
                              {index + 1}.
                            </span>
                            <p className={styles.questionInputSave}>
                              {item.question}
                            </p>
                            <div className={`${styles.questionGradient}`} />
                          </div>
                        ) : (
                          <div className={`${styles.questionLeft}`}>
                            <span className={styles.questionNumber}>
                              {index + 1}.
                            </span>
                            <div className={styles.questionIcons}>
                              <div
                                className={`${styles.sectionIcon} drag-button`}
                              >
                                <IconSortArrows />
                              </div>
                              <div
                                className={styles.sectionIcon}
                                onClick={() => handleDeleteQuestion(item)}
                              >
                                <IconTrash />
                              </div>
                              <div
                                className={styles.sectionIcon}
                                onClick={() => editQuestion(item)}
                              >
                                <IconEdit />
                              </div>
                            </div>
                          </div>
                        )}
                        {window.screen.width > 767 ? (
                          <div className={styles.questionIcons}>
                            <div
                              className={`${styles.sectionIcon} drag-button`}
                            >
                              <IconSortArrows />
                            </div>
                            <div
                              className={styles.sectionIcon}
                              onClick={() => handleDeleteQuestion(item)}
                            >
                              <IconTrash />
                            </div>
                            <div
                              className={styles.sectionIcon}
                              onClick={() => editQuestion(item)}
                            >
                              <IconEdit />
                            </div>
                          </div>
                        ) : (
                          <p className={styles.questionInputSave}>
                            {item.question}
                          </p>
                        )}
                      </div>
                    </div>
                  )
                })}
              {dropdown.questions === true &&
                (is_premium === 1 ? (
                  <button
                    type="submit"
                    className={`btn btn-dark ${styles.btnAdd}`}
                    onClick={addQuestion}
                  >
                    <IconPlus />
                    {t("buttons.add")}
                  </button>
                ) : values.length >= 1 ? (
                  <HoverTooltip
                    text={
                      <div>
                        {t("tooltips.moreQuestions1")}
                        <br />
                        {t("tooltips.moreQuestions2")}
                      </div>
                    }
                    id={"t-1"}
                  >
                    <button
                      style={{ backgroundColor: "#727F84", border: "#727F84" }}
                      type="submit"
                      className={`btn btn-dark ${styles.btnAdd}`}
                    >
                      <IconPlus />
                      {t("buttons.add")}
                    </button>
                  </HoverTooltip>
                ) : (
                  <button
                    type="submit"
                    className={`btn btn-dark ${styles.btnAdd}`}
                    onClick={addQuestion}
                  >
                    <IconPlus />
                    {t("buttons.add")}
                  </button>
                ))}
            </div>
          </div>
          <div className={styles.buttons}>
            <span className="btn btn-simple" onClick={() => setShow(false)}>
              {t("buttons.cancel")}
            </span>
            <button
              type="submit"
              className="btn btn-dark"
              onClick={() => saveModal()}
            >
              {t("buttons.save")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default OpenModal
