const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loader">
        <div className="arc"></div>
      </div>
    </div>
  )
}

export default Preloader
