import { useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const Message = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="login login--message w-100 m-auto">
      <div className="login-head">
        <h2 className="login__title w-100 text-center">{t("message.title")}</h2>
        <span className="login-desc w-100 text-center text-body-tertiary d-block">
          {t("message.description")}
        </span>
      </div>
      <Button
        className="btn btn-dark w-100 py-2"
        onClick={() => {
          navigate("/login")
        }}
      >
        {t("buttons.sign-in")}
      </Button>
    </div>
  )
}

export default Message
