import * as auth from "./auth"
import * as events from "./events"
import * as contacts from "./contacts"
import * as messages from "./messages"

const api = {
  auth,
  events,
  contacts,
  messages,
}

export default api
