import { useTranslation } from "react-i18next"
import {
  IconArrowDownTriangle,
  IconBattery,
  IconChevronUp,
  IconClose,
  IconConfirm,
  IconFooterIcons,
  IconLock,
  IconRefresh,
  IconSignal,
  IconTextSize,
  IconTime,
  IconTitle,
  IconWifi,
} from "../../utils/Icons/CustomIcons"
import "./AcceptPhone.scss"

const AcceptPhone = () => {
  const { t } = useTranslation()

  return (
    <div className="accept-phone">
      <div className="phone">
        <div className="phone-inner">
          <section className="phone-header">
            <IconTime />
            <div className="phone-header__icons">
              <IconSignal />
              <IconWifi />
              <IconBattery />
            </div>
          </section>

          <section className="phone-title">
            <IconTitle />
            <div className="phone-title__lang">
              <span>{t("event.preview.accept.lang")}</span>
              <IconArrowDownTriangle />
            </div>
          </section>

          <section className="phone-main">
            <div className="phone-main__form">
              <div className="phone-main__header">
                <IconConfirm />
                <span className="phone-main__header-title">
                  {t("event.preview.accept.headerTitle")}
                </span>
                <IconClose className="phone-main__header-close" />
              </div>
              <div className="phone-main__card">
                <p className="phone-main__card-description">
                  {t("event.preview.accept.desc")}
                </p>
                <p className="phone-main__card-input">Alfred</p>
                <p className="phone-main__card-label">
                  {t("event.preview.accept.name")}
                </p>
                <p className="phone-main__card-input">962021870187</p>
                <p className="phone-main__card-label">
                  {t("event.preview.accept.phone")}
                </p>
                <p className="phone-main__card-input">alfreddavis@gmail.com</p>
                <p className="phone-main__card-label">
                  {t("event.preview.accept.email")}
                </p>
                <p className="phone-main__card-input">100</p>
                <p className="phone-main__card-label">
                  {t("event.preview.accept.number")}
                </p>
                <p className="phone-main__card-description">
                  {t("event.preview.accept.question.title")}
                </p>
                <p className="phone-main__card-question">
                  {t("event.preview.accept.question.first")}
                </p>
                <p className="phone-main__card-label question-label">
                  {t("event.preview.accept.question.firstLabel")}
                </p>
                <div className="phone-main__card-textarea">100|</div>
                <p className="phone-main__card-question">
                  {t("event.preview.accept.question.second")}
                </p>
                <p className="phone-main__card-label question-label">
                  {t("event.preview.accept.question.secondLabel")}
                </p>
                <div className="phone-main__card-cards">
                  <div className="phone-main__card-card">100</div>
                  <div className="phone-main__card-card">200</div>
                  <div className="phone-main__card-card">300</div>
                  <div className="phone-main__card-card">400</div>
                  <div className="phone-main__card-card">500</div>
                  <div className="phone-main__card-card">600</div>
                  <div className="phone-main__card-card">700</div>
                  <div className="phone-main__card-card">800</div>
                  <div className="phone-main__card-card">900</div>
                  <div className="phone-main__card-card">1000</div>
                </div>
                <p className="phone-main__card-question">
                  {t("event.preview.accept.question.third")}
                </p>
                <p className="phone-main__card-label question-label">
                  {t("event.preview.accept.question.thirdLabel")}
                </p>
                <div className="phone-main__card-select">
                  <p className="phone-main__card-input">1</p>
                  <div className="phone-main__card-chevron">
                    <IconChevronUp />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-dark">
                  {t("event.preview.accept.question.send")}
                </button>
              </div>
            </div>
          </section>

          <section className="phone-footer">
            <div className="phone-footer__http">
              <IconTextSize />
              <IconLock />
              <span className="phone-footer__http-text">
                app.mazoom.sa/register/819
              </span>
              <IconRefresh />
            </div>
            <IconFooterIcons />
            <div className="phone-footer__http-bottom">
              <div className="phone-footer__http-line" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AcceptPhone
