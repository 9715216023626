import { loginApple } from "@/redux/slices/auth/authSlice"
import { useAppDispatch } from "@/redux/store"
import AppleSignin from "react-apple-signin-auth"
import { toast } from "react-toastify"

const LoginApple = () => {
  const notify = (text: string) => {
    toast.error(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  const dispatch = useAppDispatch()

  return (
    <AppleSignin
      authOptions={{
        clientId: "com.mazoom.sith0in-with-apple-service",
        scope: "email name",
        redirectURI: import.meta.env.VITE_REACT_APP_APPLE_REDIRECT_URI,
        usePopup: true,
      }}
      uiType="light"
      noDefaultStyle={false}
      onSuccess={(response: any) => {
        dispatch(
          loginApple({
            provider: "Apple",
            code: response.authorization.code,
          }),
        )
        console.log(response)
      }}
      onError={() => {
        notify("Login failed")
      }}
      skipScript={false}
      render={(props: any) => (
        <button
          {...props}
          className="link-login link-login--apple"
          style={{ background: "#FFF" }}
        >
          <span className="link-login__text">Apple ID</span>
        </button>
      )}
    />
  )
}

export default LoginApple
